import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from './request';

export const createApiThunk = (type) => {
  return createAsyncThunk(
    type,
    async ({ url, method, data, success, fail, contentType, navigate }, thunkAPI) => {
      try {
        const response = await request({ url, method, data, contentType });
        if (success) success(response);
        return response;
      } catch (error) {
        if (error.response.status === 401){
          navigate('/login');
        }
        if (fail) fail(error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );
};
