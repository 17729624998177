import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import refresh from '../../../../assets/images/common/refresh.svg';
import UploadFiles from '../../../../components/UploadFiles';
import ActionModal from '../../../../components/ActionModal';
import * as XLSX from 'xlsx';
import { parse } from 'papaparse';
import { get } from 'lodash';
import { excelDateToJSDate, generateUniqueId } from '../../../../utils/helper';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import CopyHierarchy from './CopyHierarchy';
import SetLevels from './SetLevels';
import { useNotification } from '../../../../utils/NotificationProvider';

const CompanyHierarchy = ({ formData, setFormData }) => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [isUploadModal, setUploadModal] = useState(false);
  const [isCopyModal, setIsCopyModal] = useState(false);
  const [isLevelModal, setIsLevelModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [copyHierarchyData, setCopyHierarchyData] = useState({});
  const [companyLevels, setCompanyLevels] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [copiedRow, setCopiedRow] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [levelOpt, setLevelOpt] = useState([]);
  const showNotification = useNotification();

  useEffect(() => {
    setCompanyLevels([{ label: 'Level 0', value: '' }]);
    setAllLevels([{ label: 'Level 0', value: '' }]);
  }, []);

  useEffect(() => {
    const levels =
      updatedColumns
        .filter((column) => column.isLevel) 
        .map((column) => ({
          value: column.id,
          label:
            column.label.charAt(0).toUpperCase() +
            column.label.slice(1).toLowerCase(),
        })) || [];
    setLevelOpt(levels);
  }, [updatedColumns]);

  const handleCloseModal = () => {
    setUploadModal(false);
    setUploadedFile(null);
  };

  const handleCloseCopyModal = () => {
    setIsCopyModal(false);
  };

  const handleCloseLevelModal = () => {
    setIsLevelModal(false);
  };

  const handleCancelLevelModal = () => {
    setAllLevels(companyLevels);
    handleCloseLevelModal();
  };

  const handleSaveLevels = () => {
    setCompanyLevels([...allLevels]);
    handleCloseLevelModal();
  };

  const handleClose = () => {
    setIsAlert(false);
  };

  const handleDelete = () => {
    setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== rowId));
    handleClose();
  };

  const getAllColumns = (newColumn) => {
    const lastColumnIndex = updatedColumns.length - 2;
    const updatedColumnsCopy = [
      ...updatedColumns.slice(0, lastColumnIndex),
      newColumn,
      ...updatedColumns.slice(lastColumnIndex),
    ];
    setUpdatedColumns(updatedColumnsCopy);
  };

  const updateColumnLabel = (allColumns) => {
    setUpdatedColumns(allColumns);
  };

  const addHierarchy = () => {
    setFilteredRows([
      ...filteredRows,
      {
        id: generateUniqueId(),
        no: '',
        name: '',
        startDate: null,
        endDate: null,
      },
    ]);
  };

  const headerButtons = [
    {
      text: 'Add Hierarchy',
      variant: 'contained',
      color: 'success',
      width: '114px !important',
      onClick: addHierarchy,
    },
    {
      text: 'Import Hierarchy',
      variant: 'outlined',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={refresh} alt="refresh" />,
      onClick: () => setUploadModal(true),
    },
    {
      text: 'Set Levels',
      variant: 'outlined',
      color: 'success',
      width: '114px !important',
      onClick: () => setIsLevelModal(true),
      isDisabled: levelOpt.length === 0
    },
  ];

  useEffect(() => {
    const stores = get(formData, 'companyHierarchy.stores', []);
    const rows = stores.length > 0 ? stores : filteredRows;
    const columnHeaders = get(formData, 'companyHierarchy.structureColumns', [])
    const columnIndex = columns.length - 1
    const lastColumnIndex = columnHeaders.length - 1;
        const columnHeadersCopy = [
          ...columnHeaders.slice(0, lastColumnIndex),
          columns[columnIndex],
        ];
    const actualColumns = columnHeaders.length > 0 ? columnHeadersCopy : columns;
    const levels = get(formData, 'companyHierarchy.level', [{ label: '', value: '' }]);
    const actualLevels = levels.length > 0 ? levels : companyLevels;
    setCompanyLevels(actualLevels);
    setAllLevels(actualLevels) 
    setFilteredRows(rows);
    setUpdatedColumns(actualColumns)
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      companyHierarchy: {
        level: companyLevels,
        stores: filteredRows,
        structureColumns: updatedColumns 
      },
    });
  }, [filteredRows, updatedColumns, companyLevels]);

  const updateFilteredRows = (rows) => {
    const data = rows.data ? rows.data : filteredRows;
    setFilteredRows(data);
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleImportData = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target.result;
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();

      let parsedData;
      let headers;

      if (fileType === 'csv') {
        const csvData = parse(fileContent, { header: true });
        parsedData = csvData.data;
        headers = csvData.meta.fields;
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        headers = worksheet[0];
        parsedData = worksheet.slice(1).map((row) =>
          row.reduce((acc, value, index) => {
            acc[headers[index]] = value;
            return acc;
          }, {})
        );
      }
      if (headers[0] !== 'no' || headers[1] !== 'name' || headers[2] !== 'startDate' || headers[3] !== 'endDate'){
        showNotification('error', "Please ensure the imported file matches the template format.")
        return;
      }
      const existingColumnIds = [
        "no",
        "name",
        "startDate",
        "endDate",
        "newCharacteristics",
        ""
    ];
      const newHeaders = headers.filter(
        (header) => !existingColumnIds.includes(header)
      );

      if (newHeaders.length > 0) {
        const newColumnsObjects = newHeaders.map((header) => ({
          id: header,
          isLevel: true,
          numeric: false,
          disablePadding: false,
          label: header.toUpperCase().replace(/_/g, ' '),
          sortable: true,
          searchFilter: true,
          filterType: 'checkbox',
          width: '10%',
          small: true,
          type: 'input',
        }));
        const actualColumns = updatedColumns.filter((item) => existingColumnIds.includes(item.id))
        const lastColumnIndex = actualColumns.length - 2;
        const updatedColumnsCopy = [
          ...actualColumns.slice(0, lastColumnIndex),
          ...newColumnsObjects,
          ...actualColumns.slice(lastColumnIndex),
        ];
        setUpdatedColumns(updatedColumnsCopy);
      }

      let parsedRows = [];
      if (fileType === 'csv') {
        parsedRows = parsedData.map((row) => ({
          id: generateUniqueId(),
          no: row.no || '',
          name: row.name || '',
          startDate: row.startDate ? row.startDate : null,
          endDate: row.endDate ? row.endDate : null,
          ...row,
        }));
      } else {
        parsedRows = parsedData.map((row) => ({
          id: generateUniqueId(),
          storeNo: row.no || '',
          name: row.name || '',
          startDate: row.startDate ? excelDateToJSDate(row.startDate) : null,
          endDate: row.endDate ? excelDateToJSDate(row.endDate) : null,
          ...row,
        }));
      }
      setFilteredRows(parsedRows)
    };

    const fileType = uploadedFile.name.split('.').pop().toLowerCase();
    if (fileType === 'csv') {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }

    handleCloseModal();
  };

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Delete hierarchy',
      variant: 'contained',
      onClick: handleDelete,
      color: 'danger',
    },
  ];

  const onActionHandle = (index, row, type) => {
    if (type === 'delete') {
      setRowId(row.id);
      setIsAlert(true);
    }
    if (type === 'copy') {
      setIsCopyModal(true);
      setCopiedRow(row);
      setCopyHierarchyData({});
    }
  };

  const handleCopyHierarchy = () => {
    const newRow = {
      ...copiedRow,
      id: generateUniqueId(),
      no: copyHierarchyData.no,
      name: copyHierarchyData.name,
    };
    setFilteredRows([...filteredRows, newRow]);
    handleCloseCopyModal();
  };

  const columns = [
    {
      id: 'no',
      numeric: false,
      disablePadding: false,
      label: 'NO.',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '3%',
      small: true,
      type: 'input',
      validInputType: 'number',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '12%',
      small: true,
      type: 'input',
    },
    {
      id: 'startDate',
      numeric: false,
      disablePadding: false,
      label: 'START DATE',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '10%',
      small: true,
      type: 'date',
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: 'END DATE',
      sortable: true,
      searchFilter: true,
      filterType: 'checkbox',
      width: '10%',
      small: true,
      type: 'date',
    },
    {
      id: 'newCharacteristics',
      numeric: false,
      disablePadding: false,
      placeholder: 'Type and press enter to add New characteristics',
      type: 'input',
      searchFilter: true,
      filterType: 'checkbox',
      headerType: 'input',
      width: '10%',
    },
    {
      id: 'button',
      numeric: true,
      disablePadding: false,
      label: '  ',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Copy' }, { title: 'Delete' }],
      },
      width: '5%',
    },
  ];

  const actionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];

  const copyActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseCopyModal },
    {
      title: 'Copy',
      variant: 'contained',
      onClick: handleCopyHierarchy,
    },
  ];

  const levelActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCancelLevelModal },
    {
      title: 'Save',
      variant: 'contained',
      onClick: handleSaveLevels,
    },
  ];

  return (
    <div>
      <div className="step-header">
        <CustomTypography variant="headerh3" text="Company Hierarchy" />
      </div>
      <EditableTable
        columns={updatedColumns}
        rows={filteredRows}
        data={filteredRows}
        tableOf={['heirarchy']}
        showStatusTabs={false}
        updateFilteredRows={updateFilteredRows}
        defaultOrderBy="calories"
        defaultOrder="asc"
        headerButtons={headerButtons}
        getAllColumns={getAllColumns}
        updateColumnLabel={updateColumnLabel}
      />
      <ActionModal
        open={isUploadModal}
        close={handleCloseModal}
        component={<UploadFiles getUploadedFile={getUploadedFile} templateSrc="/assets/company-hierarchy-template.csv"/>}
        actionButtons={actionButtons}
        title="Upload Files"
        width="32%"
      />
      <ActionModal
        open={isLevelModal}
        close={handleCloseLevelModal}
        component={
          <SetLevels
            allLevels={allLevels}
            setAllLevels={setAllLevels}
            levels={levelOpt}
          />
        }
        actionButtons={levelActionBtns}
        title="Set Levels"
        width="32%"
      />
      <ActionModal
        open={isCopyModal}
        close={handleCloseCopyModal}
        component={
          <CopyHierarchy
            copyHierarchyData={copyHierarchyData}
            setCopyHierarchyData={setCopyHierarchyData}
          />
        }
        actionButtons={copyActionBtns}
        title="Copy Hierarchy"
        width="20%"
      />
      <CustomDialogBox
        open={isAlert}
        onClose={handleClose}
        title="Delete Hierarchy"
        description="Are you sure you want to delete this hierarchy?
                       Please note, this action is irreversible."
        actions={actions}
      />
    </div>
  );
};

export default CompanyHierarchy;
