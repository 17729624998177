import * as React from 'react';
import Avatar from '@mui/material/Avatar';

const CustomAvatar = ({ name, src, color, className }) => {
  return src ? (
    <Avatar alt={name} src={src} className={className}/>
  ) : (
    <Avatar sx={{ bgcolor: color }} className={className}>{name}</Avatar>
  );
}

export default CustomAvatar
