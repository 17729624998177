import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo/re-budget-large.svg';
import lock from '../../assets/images/login/lock.svg';
import eye from '../../assets/images/login/eye.svg';
import eyeOff from '../../assets/images/login/eyeOff.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import typography from '../../theme/typography';
import TextInput from '../../components/TextInput';
import ThemeButton from '../../components/ThemeButton';
import './styles.scss';
import CustomTypography from '../../components/CustomTypography';
import { useDispatch } from 'react-redux';
import { setPassword } from '../../redux/slice/login';
import { required, validateField } from '../../utils/Validator';
import { useNotification } from '../../utils/NotificationProvider';
import PasswordStrengthMeter from './PasswordStrengthMeter';

const validationRules = {
  password: [required('Password is required')],
  confirmPassword: [required('Please confirm your password')],
};

const SetPassword = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [visibility, setVisibility] = useState({ password: true, confirmPassword: true });
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showNotification = useNotification();

  useEffect(() => {
    const userToken = location.search;
    const token = userToken.startsWith('?')
      ? userToken.substring(1)
      : userToken;
    setValues({
      ...values,
      token: token,
    });
  }, [location.search]);

  const handleChange = (fieldName) => (event) => {
    setValues({ ...values, [fieldName]: event.target.value });
  };

  const handleSubmit = () => {
    if (!validateField(values, validationRules, setErrors)) return;
    if (values.password !== values.confirmPassword) {
      showNotification('error', 'Passwords do not match');
    } else {
      dispatch(
        setPassword({
          url: '/users/password/set',
          method: 'POST',
          navigate,
          data: {
            token: values.token,
            newPassword: values.password,
          },
          success: (res) => {
            if (res && res.message){
              showNotification('success', res.message)
              }
            navigate('/login');
          },
          fail: (err) => {
            showNotification('error', err.message);
          },
        })
      );
    }
  };

  const getPasswordStrength = (strength) => {
    setPasswordStrength(strength)
  }

  const toggleVisibility = (field) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field],
    }));
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="logo-wrapper">
          <img src={logo} alt="logo"></img>
        </div>
        <div className="login-heading">
          <CustomTypography style={typography.headerh3} text="Welcome!" />
          <CustomTypography
            className="login-subtitle"
            style={typography.subtitleS1}
            text="Enter your details to get sign up to your account"
          />
        </div>
        <div className='login-form'>

          <TextInput
            label="Set password"
            placeholder="Password"
            value={values.password}
            name="password"
            type={!visibility.password ? 'text' : 'password'}
            startAdornment={<img src={lock} alt="" />}
            onChange={handleChange('password')}
            helperText={errors.password}
            width="100%"
            mb='10px'
            endAdornment={
              <img
                src={visibility.password ? eyeOff : eye}
                alt="toggle visibility"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleVisibility('password')}
              />
            }
          />
         {values.password && <PasswordStrengthMeter password={values.password || ''} getPasswordStrength={getPasswordStrength}/>}

          <TextInput
            label="Confirm password"
            placeholder="Repeat your password"
            value={values.confirmPassword}
            name="confirmPassword"
            type={!visibility.confirmPassword ? 'text' : 'password'}
            onChange={handleChange('confirmPassword')}
            helperText={errors.confirmPassword}
            width="100%"
            endAdornment={
              <img
                src={visibility.confirmPassword ? eyeOff : eye}
                alt="toggle visibility"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleVisibility('confirmPassword')}
              />
            }
          />

          <ThemeButton
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            width="100%"
            text="Sign Up"
            className="mt-20"
            isDisabled={passwordStrength < 4}
          />
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
