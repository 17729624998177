import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import palette from "../theme/palette";
import noImage from "../assets/images/common/noImage.svg"
import './styles.scss'
import CustomAvatar from "./CustomAvatar";

const ImageUpload = (props) => {
  const { uploadedFile, handleFileUpload, name } = props;
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);

  const handleUploadImage = (e) => {
    setIsUploading(true);
    handleFileUpload(e)
  }

  useEffect(() => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      if (isUploading) {
        const timer = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= 100 ? 100 : prevProgress + 10
          );
        }, 500);
        setTimeout(() => {
          clearInterval(timer);
          setProgress(0);
          setFile(uploadedFile);
        }, 5000);
        setIsUploading(false);
      } else {
        setFile(uploadedFile);
      }
    }
  }, [uploadedFile]);

  return (
    <>
      <div className="image-upload-wrapper">
        {file ? (
          <div>
            <CustomAvatar
              src={typeof file === 'string' ? file : URL.createObjectURL(file)}
              alt="Uploaded"
              className="uploaded-image"
            />
          </div>
        ) : (
          <CustomAvatar
            src={noImage}
            alt="Uploaded"
            className="uploaded-image"
          />
        )}
        <div className="border-box" style={{ borderRadius: "6px" }}>
          <input
            name={name}
            accept="image/*"
            id="raised-button-file"
            multiple
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleUploadImage(e)}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="raised"
              component="span"
              style={{ textTransform: "none", color: "black" }}
            >
              <span style={{ color: palette.primary.main }}>
                Click to upload
              </span>
            </Button>
            <div
              style={{
                fontSize: "12px",
                color: "#868FA0",
                padding: "3px 16px",
              }}
            >
              PNG, JPG or GIF (max. 800 x 400px)
            </div>
          </label>

          {progress > 0 && (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ marginTop: "5px" }}
            />
          )}
        </div>
      </div>
    </>
  );
};

ImageUpload.propTypes = {
  uploadedFile: PropTypes.string,
  name: PropTypes.string,
  handleFileUpload: PropTypes.func,
};

export default ImageUpload;
