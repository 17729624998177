import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

const CustomTooltip = ({ title, key, component, placement }) => (
  <Tooltip
    title={title}
    placement={placement}
    key={key}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -14],
            },
          },
        ],
      },
    }}
  >
    {component}
  </Tooltip>
);

CustomTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

CustomTooltip.defaultProps = {
  placement: 'left'
}

export default CustomTooltip;
