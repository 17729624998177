import React, { createContext, useContext, useState } from 'react';
import CustomNotification from '../components/CustomNotification';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    open: false,
    severity: 'info',
    message: '',
  });

  const showNotification = (severity, message) => {
    setNotification({ open: true, severity, message });
  };

  const closeNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      <CustomNotification
        severity={notification.severity}
        open={notification.open}
        message={notification.message}
        onClose={closeNotification}
      />
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
