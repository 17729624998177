import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from '../../utils/asyncThunk';

export const fetchCompanies = createApiThunk('fetchCompanies');
export const fetchCompany = createApiThunk('fetchCompany');
export const filterCompanies = createApiThunk('filterCompanies');
export const addCompany = createApiThunk('addCompany');
export const updateCompany = createApiThunk('updateCompany');
export const changeCompanyStatus = createApiThunk('changeCompanyStatus');

const companySlice = createSlice({
  name: 'companies',
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    // Get companies
    builder.addCase(fetchCompanies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCompanies.rejected, (state) => {
      state.isError = true;
    });

    // Get single company
    builder.addCase(fetchCompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompany.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCompany.rejected, (state) => {
      state.isError = true;
    });

    // Company filter
    builder.addCase(filterCompanies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(filterCompanies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(filterCompanies.rejected, (state) => {
      state.isError = true;
    });

    // Add company
    builder.addCase(addCompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addCompany.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(addCompany.rejected, (state) => {
      state.isError = true;
    });

    // Edit company
    builder.addCase(updateCompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(updateCompany.rejected, (state) => {
      state.isError = true;
    });

    // Change company status
    builder.addCase(changeCompanyStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeCompanyStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(changeCompanyStatus.rejected, (state) => {
      state.isError = true;
    });
  },
});

export default companySlice.reducer;
