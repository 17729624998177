import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ThemeButton from './ThemeButton';
import PropTypes from 'prop-types';
import './styles.scss';

const CustomDialogBox = (props) => {
  const { open, onClose, title, description, actions } = props;

  return (
    <div className='custom-dialog-box'>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" className='t-capitalize'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions?.map((action, i) => {
            return (
              <ThemeButton
                key={i}
                text={action.title}
                variant={action.variant}
                onClick={action.onClick}
                color={action.color}
              />
            );
          })}
        </DialogActions>
      </Dialog>
    </div>
  );
};

CustomDialogBox.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  actions: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
};

CustomDialogBox.defaultProps = {
  open: false,
  onClose: () => {},
  actions: [],
  title: '',
  description: '',
};

export default CustomDialogBox;
0;
