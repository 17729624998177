import React from 'react';
import PropTypes from 'prop-types';
import edit from '../../assets/images/common/edit.svg';
import trash from '../../assets/images/common/trash.svg';
import setting from '../../assets/images/common/tool.svg';
import copy from '../../assets/images/common/copy.svg';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import CustomTooltip from '../CustomTooltip';
import './styles.scss';

const ButtonCell = (props) => {
  const { onClick, buttons, info } = props;
  return (
    <>
      {buttons.map((item, index) => {
        if (item.title === 'Delete' && info.status !== 'active') {
          return (
            <CustomTooltip
              title={item.title}
              key={`cell-button-${index.toString()}`}
              component={
                <button
                  type="button"
                  className="icon-btn"
                  onClick={() => onClick(index, 'delete')}
                >
                  <img src={trash} alt="delete" />
                </button>
              }
            />
          );
        }
        if (item.title === 'Edit') {
          return (
            <CustomTooltip
              title={item.title}
              key={`cell-button-${index.toString()}`}
              component={
                <button
                  type="button"
                  className="icon-btn btn-secondary"
                  key={`cell-button-${index.toString()}`}
                  onClick={() => onClick(index, 'edit')}
                >
                  <img src={edit} alt="edit" />
                </button>
              }
            />
          );
        }
        if (item.title === 'Setting') {
          return (
            <CustomTooltip
              title={item.title}
              key={`cell-button-${index.toString()}`}
              component={
                <button
                  type="button"
                  className="icon-btn btn-secondary"
                  key={`cell-button-${index.toString()}`}
                  onClick={() => onClick(index, 'setting')}
                >
                  <img src={setting} alt="setting" />
                </button>
              }
            />
          );
        }
        if (item.title === 'Copy') {
          return (
            <CustomTooltip
              title={item.title}
              key={`cell-button-${index.toString()}`}
              component={
                <button
                  type="button"
                  className="icon-btn btn-secondary"
                  key={`cell-button-${index.toString()}`}
                  onClick={() => onClick(index, 'copy')}
                >
                  <img src={copy} alt="copy" />
                </button>
              }
            />
          );
        }
        if (item.title === 'Check') {
          return (
            <CustomTooltip
              title={item.title}
              key={`cell-button-${index.toString()}`}
              component={
                <button
                  type="button"
                  className="icon-btn btn-secondary"
                  key={`cell-button-${index.toString()}`}
                  onClick={() => onClick(index, 'check')}
                >
                  <i className="fa-solid fa-check"></i>
                </button>
              }
            />
          );
        }
        if (item.title === 'Cross') {
          return (
            <CustomTooltip
              title={item.title}
              key={`cell-button-${index.toString()}`}
              component={
                <button
                  type="button"
                  className="icon-btn btn-secondary"
                  key={`cell-button-${index.toString()}`}
                  onClick={() => onClick(index, 'cross')}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              }
            />
          );
        }
        if (item.title === 'Active' && info.status === 'archive') {
          return (
            <CustomTooltip
              title={item.title}
              key={`cell-button-${index.toString()}`}
              component={
                <button
                  type="button"
                  className="icon-btn btn-secondary play-btn"
                  key={`cell-button-${index.toString()}`}
                  onClick={() => onClick(index, 'active')}
                >
                  <PlayArrowOutlinedIcon />
                </button>
              }
            />
          );
        }
        if (item.title === 'Archived' && info.status === 'active') {
          return (
            <CustomTooltip
              title={item.title}
              key={`cell-button-${index.toString()}`}
              component={
                <button
                  type="button"
                  className="icon-btn btn-secondary archived-btn"
                  key={`cell-button-${index.toString()}`}
                  onClick={() => onClick(index, 'archived')}
                >
                  <ArchiveOutlinedIcon />
                </button>
              }
            />
          );
        }
      })}
    </>
  );
};

ButtonCell.propTypes = {
  onClick: PropTypes.func,
  info: PropTypes.any,
  buttons: PropTypes.array,
};

ButtonCell.defaultProps = {
  onClick: () => {},
  info: null,
  buttons: [],
};

export default ButtonCell;
