import axios from 'axios';
import { appConfig } from '../config/appConfig';

export const axiosInstance = axios.create({
  baseURL: appConfig.baseURL
});

export const request = async ({ url, method, data, contentType }) => {
  const headers = {
    Authorization: localStorage.getItem('token') || '',
  }

  if (contentType){
    headers['Content-Type'] = contentType
  }

  const response = await axiosInstance({ url, method, data, headers: headers });
  return response.data;
};
