import AppRoutes from "../routes";
import Header from "./header";
import Sidebar from "./sidebar";

const MainLayout = () => {
  return (
    <div>
      <div>
        <Sidebar />
        <Header />
        <main className="main-wrapper">
          <AppRoutes />
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
