import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from '../../utils/asyncThunk';

export const fetchPermissions = createApiThunk('fetchPermissions');

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    // Get Permissions
    builder.addCase(fetchPermissions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchPermissions.rejected, (state) => {
      state.isError = true;
    });
  },
});

export default permissionsSlice.reducer;
