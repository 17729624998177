import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import fileImg from '../assets/images/common/file.svg';
import document from '../assets/images/common/chart.svg';
import ProgressCircle from './ProgressCircle';
import typography from '../theme/typography';
import CustomTypography from './CustomTypography';
import { allowedFileTypes } from '../utils/constants';

const UploadFiles = ({ getUploadedFile, templateSrc, additionalNote }) => {
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append('file', uploadedFile);
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 10
        );
      }, 500);
      setTimeout(() => {
        clearInterval(timer);
        setUploadedFile(uploadedFile);
        getUploadedFile(uploadedFile);
      }, 5000);
    }
  }, [uploadedFile]);

  const handleFileUpload = (file) => {
    if (allowedFileTypes.includes(file.type)) {
      setUploadedFile(file);
    } else {
      alert('Only CSV, Excel, OFX, QIF, XML, or TXT files are allowed.');
    }
  };

  const onInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  return (
    <div
      className="upload-file-wrapper"
    >
      <div className="image-upload-wrapper">
        <div>
          <CustomTypography text="Step 1" style={typography.bodyB2}/>
          <div style={typography.subtitleS1}>Click <a href={templateSrc} download>here</a> to download the latest version of the import template.</div>
        </div>
        <div>
          <CustomTypography text="Step 2" style={typography.bodyB2}/>
          <CustomTypography text="Populate the downloaded file following the instructions within." style={typography.subtitleS1}/>
        </div> 
        <div>
        <CustomTypography text="Step 3" style={typography.bodyB2}/>
        <CustomTypography text="Once is ready, Click below to select your populated file." style={typography.subtitleS1}/>
        </div>
        <div className="border-box upload-file d-flex-center">
          {progress > 0 ? (
            <ProgressCircle
              variant="determinate"
              value={progress}
              sx={{ marginTop: '5px' }}
              // showText={true}
            />
          ) : (
            <>
              <label htmlFor="raised-button-file">
                <div className="upload-file-container">
                  <div className="upload-file-img">
                    <div className="icon_wrapper file">
                      <img src={fileImg} />
                    </div>
                    <div className="icon_wrapper chart">
                      <img src={document} />
                    </div>
                  </div>
                  <div className="d-flex">
                    <span
                      className="upload-file-btn"
                      style={typography.subtitle2}
                    >
                      Select a file
                    </span>
                    &nbsp;
                    <CustomTypography
                      text="to upload"
                      style={typography.bodyB2}
                    />
                  </div>
                  <CustomTypography
                    text="CSV, Excel, OFX, QIF, XML or TXT"
                    style={typography.overline}
                  />
                </div>
              </label>
              <input
                name=""
                // ref={fileInputRef}
                accept=".csv, .xls, .xlsx, .ofx, .qif, .xml, .txt"
                id="raised-button-file"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={onInputChange}
              />
            </>
          )}
        </div>
      </div>
      <div className="divider"></div>
      {additionalNote && 
        <div className='bg-white note-container'>
          <CustomTypography text={additionalNote} style={typography.bodyB2}/>
        </div>
      }
      <div>
      </div>
    </div>
  );
};

UploadFiles.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};
export default UploadFiles;
