import { createTheme } from "@mui/material";
import palette from "./palette";
import typography from "./typography";
import MuiDialog from "./overrides/MuiDialog";
import MuiMenu from "./overrides/MuiMenu";
import MuiAccordion from "./overrides/MuiAccordion";

const theme = createTheme({
    palette,
    typography: typography,
    components: {
        MuiDialog,
        MuiMenu,
        MuiAccordion,
    },
    zIndex: {}
});

export default theme;