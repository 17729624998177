export const isValidEmail = (message) => (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value) ? null : message || 'Invalid email address';
};

export const phoneRegex = /^\d{0,10}$/;

export const isValidPhoneNumber = (message) => (value) => {
  return /^\d{10}$/.test(value) ? null : message || 'Invalid phone number';
};

export const validateInput = (message) => (input) => {
  const regex = /^\s*$/;
  if (regex.test(input)) {
      return message;
  } 
}

export const excelDateToJSDate = (serial) => {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  return new Date(utc_value * 1000);
};

import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';
const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (message, defaultRegion = 'US') => (value) => {
  try {
    if (value.length <= 3) {
      return message;
    }
    
    const phoneNumber = phoneUtil.parseAndKeepRawInput(value, defaultRegion);
    if (!phoneUtil.isValidNumber(phoneNumber)) {
      return message;
    }
  } catch (error) {
    return message;
  }
};

export const validatePassword = () => (password) => {
 if (password?.trim()){ 
  if (password.length < 8) {
    return 'Password must be at least 8 characters long.'
  }
  if (!/[a-z]/.test(password)) {
    return 'Password must contain at least one lowercase letter.'
  }
  if (!/[A-Z]/.test(password)) {
    return 'Password must contain at least one uppercase letter.'
  }
  if (!/\d/.test(password)) {
    return 'Password must contain at least one number.'
  }
  if (!/[!@#?]/.test(password)) {
    return 'Password must contain at least one special character (!@#?]).'
  }
  return false;
}
};

export const generateUniqueId = () => {
  return Date.now() + Math.floor(Math.random() * 1000); 
};

export const formatDate = (date) => {
  const isValidFormat = moment(date, "DD/MM/YYYY", true).isValid();
  if (isValidFormat) {
    return moment(date, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  } else {
    return date;
  }
};