import CustomTypography from "../../components/CustomTypography";
import NoDataFoundMessage from "../../components/NoDataFoundMessage";
import ThemeButton from "../../components/ThemeButton";
import theme from "../../theme";

const Report = () => {
    return <div>
      <NoDataFoundMessage title="No data available yet." content={
        <>
          <CustomTypography
            variant="subtitleS1"
            text="Start by creating a model and run your budget to see meaningful insights."
            color={theme.palette.text.primary}
          />
          <ThemeButton
            variant="contained"
            style={{ height: "32px" }}
            text="Create model"
            onClick={() => { }}
          />
        </>
      } />
    </div>;
  };
  
  export default Report;
  