// import React, { useState } from 'react';
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';
// import palette from '../theme/palette';

// const CustomNotification = ({ severity, open, message }) => {
//   console.log('severity in not', severity);
//   const [openAlert, setOpenAlert] = useState(open);

//   const handleClose = () => {
//     setOpenAlert(false);
//   };

//   const getCustomStyles = (severity) => {
//     switch (severity) {
//       case 'success':
//         return { backgroundColor: palette.alerts.success };
//       case 'error':
//         return { backgroundColor: palette.alerts.danger };
//       case 'info':
//         return {
//           backgroundColor: palette.alerts.info,
//           color: palette.heading.default,
//         };
//       default:
//         return {};
//     }
//   };

//   return (
//     <div className="custom-alert">
//       <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
//         <Alert
//           onClose={handleClose}
//           severity={severity}
//           variant="filled"
//           sx={getCustomStyles(severity)}
//         >
//           {message}
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// };

// export const handleNotification = (severity, message) => {
//   console.log('severity', severity);
//   return (
//     <CustomNotification severity={severity} message={message} open={true} />
//   );
// };
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import palette from '../theme/palette';

const CustomNotification = ({ severity, open, message, onClose }) => {
  const getCustomStyles = (severity) => {
    switch (severity) {
      case 'success':
        return { backgroundColor: palette.alerts.success };
      case 'error':
        return { backgroundColor: palette.alerts.danger };
      case 'info':
        return {
          backgroundColor: palette.alerts.info,
          color: palette.heading.default,
        };
      default:
        return {};
    }
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={getCustomStyles(severity)}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomNotification;

