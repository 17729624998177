export const appConfig = {
  // url: 'http://localhost:3000/',
  url: 'https://server108.absolutebyte.co.uk/',
  // baseURL: 'http://localhost:3000/api/v1',
  baseURL: 'https://server108.absolutebyte.co.uk/api/v1',
  userRoles: [
    { label: 'Super user', value: 0 },
    { label: 'Admin', value: 1 },
    { label: 'Approver', value: 2 },
    { label: 'User', value: 3 },
  ],
};
