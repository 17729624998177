import React, { useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import SelectDropdown from '../../../../components/SelectDropdown';
import './styles.scss';
import ThemeButton from '../../../../components/ThemeButton';
import minusCircle from '../../../../assets/images/common/minusCircle.svg';
import addCircle from '../../../../assets/images/common/addCircle.svg';

const SetLevels = ({ allLevels, setAllLevels, levels }) => {
  const [selectedValues, setSelectedValues] = useState(
    allLevels.map((level) => level.value)
  );

  const handleSelectChange = (value, index) => {
    const updatedLevels = [...allLevels];
    updatedLevels[index].value = value;

    const updatedSelectedValues = [...selectedValues];
    updatedSelectedValues[index] = value;

    setAllLevels(updatedLevels);
    setSelectedValues(updatedSelectedValues);
  };

  const getFilteredItems = (index) => {
    return levels.filter(
      (item) =>
        !selectedValues.includes(item.value) ||
        selectedValues[index] === item.value
    );
  };

  const handleAddlevel = () => {
    const newLevel = { label: `Level ${allLevels.length}`, value: '' };
    setAllLevels([...allLevels, newLevel]);
    setSelectedValues([...selectedValues, '']);
  };

  const handleRemoveLevel = (index) => {
    const updatedLevels = allLevels.filter((_, i) => i !== index);
    setAllLevels(updatedLevels);
    const updatedSelectedValues = selectedValues.filter((_, i) => i !== index);
    setSelectedValues(updatedSelectedValues);
  };

  const levelCard = (level, index) => {
    return (
      <div className="level-card-container d-flex" key={index}>
        <CustomTypography text={`Level ${index}`} />
        <SelectDropdown
          minWidth="100%"
          items={getFilteredItems(index)}
          value={level?.value}
          handleChange={(e) => handleSelectChange(e.target.value, index)}
        />
        {allLevels.length > 1 && (
          <ThemeButton
            variant="text"
            startIcon={<img src={minusCircle} alt="Remove Level" />}
            onClick={() => handleRemoveLevel(index)}
          />
        )}
      </div>
    );
  };

  return (
    <div className="pd-24 d-flex fd-column level-wrapper">
      {allLevels.map((level, index) => {
        return levelCard(level, index);
      })}
      {allLevels.length < levels.length && (
        <ThemeButton
          variant="text"
          startIcon={<img src={addCircle} />}
          onClick={handleAddlevel}
        />
      )}
    </div>
  );
};

export default SetLevels;
