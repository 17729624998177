import CustomAvatar from '../components/CustomAvatar';
import './styles.scss';

const Header = () => {
  const userName = localStorage.getItem('firstName')
  const userInitial = userName?.charAt(0)

  return (
    <div className="header">
      <div className="header-container">
        <div className="customer-select">
        </div>
        <div className="profile-btn">
          <CustomAvatar name={userInitial} color="#864293"/>
        </div>
      </div>
    </div>
  );
};

export default Header;
