import React from 'react';
import CustomTypography from '../../../../components/CustomTypography';

const Calculations = () => {
  return (
    <div className="form-title">
      <CustomTypography
        variant="headerh3"
        text="Calculations"
      />
    </div>
);
};

export default Calculations;
