export const inputValueType = [
  { label: 'Date', value: 'Date', type: 'date' },
  { label: 'Currency', value: 'Currency', type: 'number' },
  { label: 'Number', value: 'Number', type: 'number' },
  { label: 'Percent', value: 'Percent', type: 'number' },
  { label: 'Free', value: 'Free', type: 'text' },
];

export const groupsData = [
  { label: 'Group 1', value: '1' },
  { label: 'Group 2', value: '2' },
];

export const ValueTypes = [
  { value: 1, label: "Date", type: "date" },
  { value: 2, label: "Currency", type: "currency" },
  { value: 3, label: "Number", type: "number" },
  { value: 4, label: "Percent", type: "percent" },
  { value: 5, label: "Free", type: "free" },
];

export const dailyGroupData = [
  {
    group: 1,
    groupName: 'Group 1',
    parameters: [
      {
        id: 1,
        parameterName: 'Average lines of pastries stocked per store',
        value: {
          SUN: '12',
          MON: '12',
          TUE: '12',
          WED: '12',
          THU: '12',
          FRI: '12',
          SAT: '12',
        },
        type: 2,
      },
      {
        id: 2,
        parameterName: 'Average lines of cakes stocked per store',
        value: {
          SUN: '12',
          MON: '12',
          TUE: '12',
          WED: '12',
          THU: '12',
          FRI: '12',
          SAT: '12',
        },
        type: 1,
      },
    ],
  },
  {
    group: 2,
    groupName: 'Group 2',
    parameters: [
      {
        id: 3,
        parameterName: 'Average lines of bread stocked per store',
        value: {
          SUN: '12',
          MON: '12',
          TUE: '12',
          WED: '12',
          THU: '12',
          FRI: '12',
          SAT: '12',
        },
        type: 3,
      },
      {
        id: 4,
        parameterName:
          'Average lines of sandwiches and toasties stocked per store',
        value: {
          SUN: '12',
          MON: '12',
          TUE: '12',
          WED: '12',
          THU: '12',
          FRI: '12',
          SAT: '12',
        },
        type: 3,
      },
      {
        id: 5,
        parameterName: 'Average number of lines stocked per store',
        value: {
          SUN: '12',
          MON: '12',
          TUE: '12',
          WED: '12',
          THU: '12',
          FRI: '12',
          SAT: '12',
        },
        type: 4,
      },
    ],
  },
];
export const weeklyGroupData = [
  {
    group: 1,
    groupName: 'Group 1',
    parameters: [
      {
        id: 1,
        parameterName: 'Average lines of pastries stocked per store',
        value: '14',
        type: 4,
      },
      {
        id: 2,
        parameterName: 'Average lines of cakes stocked per store',
        value: '21',
        type: 5,
      },
    ],
  },
  {
    group: 2,
    groupName: 'Group 2',
    parameters: [
      {
        id: 3,
        parameterName: 'Average lines of bread stocked per store',
        value: '2024-07-06',
        type: 1,
      },
      {
        id: 4,
        parameterName:
          'Average lines of sandwiches and toasties stocked per store',
        value: '10',
        type: 2,
      },
      {
        id: 5,
        parameterName: 'Average number of lines stocked per store',
        value: '71',
        type: 3,
      },
    ],
  },
];
