import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
// import MuiChipCell from "./MuiChipCell";
// import ButtonCell from "./ButtonCell";
// import TableHeader from "./TableHeader";
import { TableFooter } from "@mui/material";
// import EnhancedToolbar from "./EnhancedToolbar";
// import checkCircle from "../../../assets/images/checkCircle.svg";
// import TextInput from "../TextInput";
// import CustomDatePicker from "../CustomDatePicker";
// import UploadFiles from "../UploadFiles";
// import typography from "../../../theme/typography";
// import dayjs from "dayjs";
// import CustomAutocomplete from "../CustomAutocomplete";
import SelectDropdown from "../SelectDropdown";
import TextInput from "../TextInput";
import typography from "../../theme/typography";
import './styles.scss';
import TableHeader from "./TableHeader";
import EnhancedToolbar from "./EnhancedToolbar";
import CustomDatePicker from "../CustomDatePicker";
import ButtonCell from "./ButtonCell";
import dayjs from "dayjs";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
};
// const getColumnBackgroundColor = (index) => {
//   if (index === 0 || index === 1) {
//     return "#e9edf5";
//   } else if (index < 13) {
//     return "#d2e3e4";
//   } else {
//     return "#e0eee7";
//   }
// };
const EditableTable = ({
  rows,
  columns,
  defaultOrderBy,
  defaultOrder,
  selectable,
  pagination,
  headerButtons,
  tableOf,
  updateFilteredRows,
  data,
  onDelete,
  isSearchRequired,
  titleOFthePage,
  showTableHeader,
  cellStyle,
  dropdownItems,
  selectedDropDownValue,
  onChange,
  getColumnBackgroundColor,
  footerRow,
  editingRow,
  showStatusTabs,
  getAllColumns,
  updateColumnLabel
}) => {
  const [order, setOrder] = React.useState(defaultOrder || "asc");
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [columnLabel, setColumnLabel] = React.useState("");

//   const [uploadFiles, setUploadFiles] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n[columns[0].id]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleOnUnselect = () => {
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

//   const handleCloseUploadModal = () => {
//     setUploadFiles(false);
//   };

  // const validateSections = (value, column) => {
  //   if (
  //     (column.validInputType === "text" && !/^[a-zA-Z]*$/.test(value)) ||
  //     (column.validInputType === "number" && !/^[0-9]*$/.test(value))
  //   ) {
  //     return true;
  //   }
  // };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => {
      const sortedRows = stableSort(rows, getComparator(order, orderBy));
      return sortedRows;
    },
    [order, orderBy, page, rowsPerPage, rows]
  );

  const handleInputChange = (value, columnId, currentRow) => {
    const rowIndex = rows.findIndex((row) => row === currentRow);
    const newRows = [...rows];
    newRows[rowIndex][columnId] = value;
    updateFilteredRows({data: newRows});
  };

  const shouldDisableDate = (date, columnId, currentRow) => {
    const { startDate, endDate } = currentRow;
  if (columnId === 'startDate') {
    return endDate ? date > new Date(endDate) : false;
  }
  if (columnId === 'endDate') {
    return startDate ? date < new Date(startDate) : false;
  }
  return false;
  }

  const handleSelectChange = (value, columnId, currentRow, options) => {
    const rowIndex = rows.findIndex((row) => row === currentRow);
    const newRows = [...rows];
    const selectedValue = options?.filter((opt) => opt.value === value);
    newRows[rowIndex][columnId] = selectedValue[0];
    updateFilteredRows({data: newRows});
  };

  const handleColumnInputChange = (value) => {
    setColumnLabel(value);
  };

  const addNewColumn = () => {
    const newColumnName = columnLabel;
    const newColumn = {
      id: newColumnName?.toLowerCase().replace(" ", ""),
      numeric: false,
      disablePadding: false,
      label: newColumnName,
      searchFilter: true,
      filterType: "checkbox",
      type: "input",
      headerType: "input",
      width: '10%',
      isLevel: true,
    };
    getAllColumns(newColumn);
    setColumnLabel("");
  };
//   const handleAutocompleteChange = (value, currentRow, column, newValue) => {
//     const { id, options, toUpdateOptions } = column;
//     let selectedValue;
//     if (newValue && newValue.inputValue) {
//       selectedValue = { label: newValue.inputValue, value: options.length + 1 };
//     } else {
//       selectedValue = newValue;
//     }
//     const rowIndex = rows.findIndex((row) => row === currentRow);
//     const newRows = [...rows];
//     newRows[rowIndex][id] = selectedValue;
//     if (newValue && newValue.inputValue) {
//       toUpdateOptions([
//         ...options,
//         { label: newValue.inputValue, value: options.length + 1 },
//       ]);
//     }
//     updateFilteredRows(newRows);
//   };

  const editCell = (column, row) => {
    // if (row.id === editingRow) {
      switch (column.type) {
        case "dropdown":
          return (
            <SelectDropdown
              value={
                column?.options?.filter(
                  (opt) =>
                    opt.value === row[column.id].value &&
                    opt.label === row[column.id].label
                )[0]?.value
              }
              items={column.options || []}
              handleChange={(e) =>
                handleSelectChange(
                  e.target.value,
                  column.id,
                  row,
                  column.options
                )
              }
            />
          );
        // case "autocomplete":
        //   return (
        //     <CustomAutocomplete
        //       options={column.options}
        //       updateOptions={column.toUpdateOptions}
        //       value={
        //         column?.options?.filter(
        //           (opt) =>
        //             opt?.value === row[column.id]?.value &&
        //             opt?.label === row[column.id]?.label
        //         )[0]
        //       }
        //       handleChange={(e, newValue) =>
        //         handleAutocompleteChange(e.target.value, row, column, newValue)
        //       }
        //     />
        //   );
        case "input":
          if (column.id === 'newCharacteristics'){
            return (
              <div>
                <TextInput
                  type={column.validInputType || 'text'}
                  value=''
                  width="auto"
                  placeholder='e.g.1'
                  disabled
                />
              </div>
            );
          } else {
            return (
              <div>
                <TextInput
                  type={column.validInputType || 'text'}
                  value={row[column.id]}
                  width="auto"
                  onInputChange={(e) =>
                    handleInputChange(e.target.value, column.id, row)
                  }
                />
              </div>
            );
          }
        // return (
        //   <TextInput
        //     className="bg-white"
        //     type="text"
        //     value={row[column.id]}
        //     width={column.width}
        //     onInputChange={(e) =>
        //       handleInputChange(e.target.value, column.id, row)
        //     }
        //   />
        // );
        case "date":
          return (
            <CustomDatePicker
              value={row[column.id] ? dayjs(row[column.id], 'DD/MM/YYYY') : null}
              className="bg-white"
              onChange={(date) => handleInputChange(date, column.id, row)}
              shouldDisableDate={(date) => shouldDisableDate(date, column.id, row)}
            />
          );
        case "buttons":
          return (
            <ButtonCell
              buttons={column.moreOptions.buttons}
              info={row}
              onClick={(index, type) => {
                column.moreOptions.buttonClick(index, row, type)
              }}
            />
          );
        case "customButton":
          return column.component ? (
            <column.component
              onClick={(type) => column.buttonOnClick(type, row)}
              row={row}
              editingRowId={editingRow}
            />
          ) : null;
        default:
          return row[column.id];
      }
    // } else {
    //   switch (column.type) {
    //     // case "chip":
    //     //   return <MuiChipCell initialChips={row[column.id]} />;
    //     // case "buttons":
    //     //   return (
    //     //     <ButtonCell
    //     //       buttons={column.moreOptions.buttons}
    //     //       info={row}
    //     //       onClick={(index, type) =>
    //     //         column.moreOptions.buttonClick(index, row, type)
    //     //       }
    //     //     />
    //     //   );
    //     case "customButton":
    //       return column.component ? (
    //         <column.component
    //           onClick={(type) => column.buttonOnClick(type, row)}
    //           row={row}
    //           editingRowId={editingRow}
    //         />
    //       ) : null;
    //     case "logo":
    //       return <Avatar alt="Remy Sharp" src={row[column.id]} />;
    //     // case "bool":
    //     //   return <img src={row[column.id] && checkCircle} alt="" />;
    //     case "dropdown":
    //       return row[column.id].label;
    //     case "autocomplete":
    //       return row[column.id]?.label;
    //     // case "date":
    //     //   return dayjs(row[column.id]).format("DD MMMM YYYY");
    //     default:
    //       return row[column.id];
    //   }
    // }
  };

  return (
    <div className="editable-table-wrapper table-wrapper">
      <TableHeader
        headerButtons={headerButtons}
        columns={columns}
        rows={rows}
        updateFilteredRows={updateFilteredRows}
        data={data}
        isSearchRequired={isSearchRequired}
        titleOFthePage={titleOFthePage}
        showTableHeader={showTableHeader}
        dropdownItems={dropdownItems}
        selectedDropDownValue={selectedDropDownValue}
        onChange={onChange}
        showStatusTabs={showStatusTabs}
      />

      {selected && selected.length > 0 && (
        <EnhancedToolbar
          selectedItems={selected}
          tableOf={tableOf}
          onUnSelect={handleOnUnselect}
          onDelete={onDelete}
        />
      )}
      {/* Table Container */}
      <TableContainer sx={{ maxWidth: "94vw", scrollbarWidth: "thin" }}>
        {/* Table */}
        <Table
          sx={{ minWidth: 750, overflow: "auto" }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          {/* Table Head */}
          <TableHead>
            <TableRow>
              {selectable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < rows.length
                    }
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all items",
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    align={column.numeric ? "right" : "left"}
                    padding={column.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleRequestSort(null, column.id)}
                      >
                        <Typography style={typography.overline}>
                          {column.label}
                        </Typography>
                        {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : column.headerType === "input" ? (
                      column.id === "newCharacteristics" ? (
                        <TextInput
                          className="bg-white"
                          type="text"
                          value={columnLabel || ""}
                          // width={column.width}
                          placeholder={column.placeholder}
                          onInputChange={(e) =>
                            handleColumnInputChange(e.target.value)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter")
                              addNewColumn();
                            }}
                        />
                      ) : (
                        <TextInput
                          type="text"
                          value={column.label || ""}
                          className="bg-white"
                          onInputChange={(e) => {
                            const newColumns = columns.map((col) =>
                              col.id === column.id
                                ? { ...col, label: e.target.value }
                                : col
                            );
                            updateColumnLabel(newColumns);
                          }}
                        />
                      )
                    ) : (
                      <Typography style={typography.overline}>
                        {column.label}
                      </Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {visibleRows && visibleRows.length > 0 ? (
              visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row[columns[0].id]);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row[columns[0].id]}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {selectable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleClick(event, row[columns[0].id])
                          }
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.map((column, index) => (
                      <TableCell
                        key={column.id}
                        sx={{width: column.width}}
                        align={column.numeric ? "right" : "left"}
                        className="datepicker-div"
                        style={{
                          backgroundColor: cellStyle
                            ? getColumnBackgroundColor(index)
                            : "",
                          padding: cellStyle ? "8px" : "",
                          borderBottom: cellStyle ? "1px solid white" : "",
                        }}
                      >
                        {editCell(column, row)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={columns.length + (selectable ? 1 : 0)}>
                  <Typography variant="body1">No results found</Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={columns.length + (selectable ? 1 : 0)} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              {footerRow &&
                columns.map((column) => (
                  <TableCell key={column.id}>{footerRow[column.id]}</TableCell>
                ))}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* Table Pagination */}
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {/* {uploadFiles && (
        <UploadFiles open={open} close={handleCloseUploadModal} />
      )} */}
    </div>
  );
};

EditableTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultOrderBy: PropTypes.string,
  headerButtons: PropTypes.array,
  defaultOrder: PropTypes.oneOf(["asc", "desc"]),
  selectable: PropTypes.bool,
  pagination: PropTypes.bool,
  tableOf: PropTypes.array,
  data: PropTypes.array,
  updateFilteredRows: PropTypes.func,
  onDelete: PropTypes.func,
  isSearchRequired: PropTypes.bool,
  titleOFthePage: PropTypes.string,
  showTableHeader: PropTypes.bool,
  cellStyle: PropTypes.bool,
  dropdownItems: PropTypes.array,
  selectedDropDownValue: PropTypes.string,
  onChange: PropTypes.func,
  getColumnBackgroundColor: PropTypes.func,
  footerRow: PropTypes.object,
  editingRow: PropTypes.object,
};
EditableTable.defaultProps = {
  isSearchRequired: true,
  showTableHeader: true,
  showStatusTabs: true,
  rows: [],
};
export default EditableTable;
