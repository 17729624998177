import React from 'react';
import TextInput from '../../../../components/TextInput';

const CopyHierarchy = ({ copyHierarchyData, setCopyHierarchyData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCopyHierarchyData({
      ...copyHierarchyData,
      [name]: value,
    });
  };

  return (
    <div className="pd-24 d-flex fd-column" style={{ gap: '24px' }}>
      <TextInput
        label="New location name"
        name="name"
        value={copyHierarchyData.name}
        onInputChange={handleChange}
      />
      <TextInput
        label="Store Number"
        name="no"
        type="number"
        value={copyHierarchyData.no}
        onInputChange={handleChange}
      />
    </div>
  );
};

export default CopyHierarchy;
