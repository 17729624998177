import React from "react";
import CustomTypography from "../../../../components/CustomTypography";

const Roles = () => {
   return (
      <div className="form-title">
        <CustomTypography
          variant="headerh3"
          text="Roles"
        />
      </div>
  );
}

export default Roles;