import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from '../../utils/asyncThunk';

export const fetchUsers = createApiThunk('fetchUsers');
export const filterUsers = createApiThunk('filterUsers');
export const fetchUser = createApiThunk('fetchUser');
export const addUser = createApiThunk('addUser');
export const updateUser = createApiThunk('updateUser');
export const changeUserStatus = createApiThunk('changeUserStatus');

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    // Get Users
    builder.addCase(fetchUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.isError = true;
    });

    // Filter Users
    builder.addCase(filterUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(filterUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(filterUsers.rejected, (state) => {
      state.isError = true;
    });

    // Get single User
    builder.addCase(fetchUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUser.rejected, (state) => {
      state.isError = true;
    });

    // Add User
    builder.addCase(addUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(addUser.rejected, (state) => {
      state.isError = true;
    });

    // Edit User
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.isError = true;
    });

    // Change User Status
    builder.addCase(changeUserStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeUserStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(changeUserStatus.rejected, (state) => {
      state.isError = true;
    });
  },
});

export default usersSlice.reducer;
