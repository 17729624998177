import React from 'react';
import CustomTypography from '../../../../components/CustomTypography';

const Calendar = () => {
  return (
    <div className="form-title">
      <CustomTypography
        variant="headerh3"
        text="Calendar"
      />
    </div>
);
};

export default Calendar;
