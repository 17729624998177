export const required = (message) => (value) => {
  return value ? null : message || 'This field is required';
};

export const validate = (values, rules) => {
    const errors = {};
  
    for (const field in rules) {
      const value = values[field];
      const fieldRules = rules[field];
  
      for (const rule of fieldRules) {
        const error = rule(value);
        if (error) {
          errors[field] = error;
          break; 
        }
      }
    }
  
    return errors;
  };

  export const validateField = (values, validationRules, setErrors, currentErrors = {}) => {
    const validationErrors = validate(values, validationRules);
    const updatedErrors = { ...currentErrors, ...validationErrors };
    Object.keys(values).forEach((field) => {
      if (!validationErrors[field]) {
        delete updatedErrors[field];
      }
    });
    setErrors(updatedErrors);
    return Object.keys(updatedErrors).length === 0;
  };
  
  
  