import React from 'react';
import PropTypes from 'prop-types';
import noDataFoundImage from '../assets/images/common/noDataFoundImage.png'
import CustomTypography from './CustomTypography';
import theme from '../theme';

const NoDataFoundMessage = ({title, content}) => {
  return (
    <div className='not-found-container'>
        <img src={noDataFoundImage} alt='no data found' />
        <div className='text'>
            <CustomTypography
              variant="h2"
              text={title}
              color={theme.palette.text.primary}
            />
            {content}
        </div>
    </div>
  )
}

export default NoDataFoundMessage

NoDataFoundMessage.propTypes = {
    title: PropTypes.string,
    content: PropTypes.object
};