export default {
    styleOverrides: {
      root: {
        '& .MuiDialogTitle-root': {
            color: "#171C26",
            fontWeight: 700,
            fontSize: "20px",
        },
        '& .MuiDialogActions-root': {
            borderTop: "1px solid #E9EDF5",
            justifyContent: "space-between",
            padding: "16px 24px",
        },
        '& .MuiDialog-paper': {
           maxWidth: '400px',
           borderRadius: '12px'
        },
        '& .MuiDialogContentText-root': {
          textTransform: 'none',
        }
      }
    }
  };