import React, { useState } from 'react';
import logo from '../../assets/images/logo/re-budget-large.svg';
import typography from '../../theme/typography';
import ThemeButton from '../../components/ThemeButton';
import { MuiOtpInput } from 'mui-one-time-password-input';
import './styles.scss';
import CustomTypography from '../../components/CustomTypography';
import { useNotification } from '../../utils/NotificationProvider';
import Timer from './timer';
import { useDispatch } from 'react-redux';
import { resendOtp, verifyOtp } from '../../redux/slice/login';
import { useNavigate } from 'react-router-dom';

const OtpVerification = () => {
  const showNotification = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState();
  const [time, setTime] = useState(120);
  const [loading, setLoading] = useState(false)

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleSubmit = () => {
    if (otp && otp.length === 6) {
      setLoading(true)
      dispatch(
        verifyOtp({
          url: '/users/login/otp',
          method: 'POST',
          navigate,
          data: {
            email: localStorage.getItem('userEmail'),
            OTP: otp,
          },
          success: (res) => {
            localStorage.removeItem('userEmail');
            if (res && res.data && res.data.token) {
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('firstName', res.data.firstName);
            }
            setLoading(false)
            navigate('/dashboard');
          },
          fail: (err) => {
            showNotification('error', err.message);
            setLoading(false)
          },
        })
      );
    } else {
      showNotification('error', 'Enter correct OTP');
    }
  };

  const handleResendOtp = () => {
    setOtp();
    setLoading(true)
    dispatch(
      resendOtp({
        url: '/users/login/otp/resend',
        method: 'POST',
        navigate,
        data: {
          token: localStorage.getItem('otpToken'),
        },
        success: (res) => {
          localStorage.removeItem('otpToken');
          if (res && res.message === 'OTP sent successfully.') {
            setTime(120);
            if (res.data) {
              localStorage.setItem('otpToken', res.data);
            }
          }
          setLoading(false)
        },
        fail: (err) => {
          showNotification('error', err.message);
          setLoading(false)
        },
      })
    );
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <img src={logo} alt="logo" className='logo-image'/>
        <div className="login-heading">
          <CustomTypography
            style={typography.headerh3}
            text="OTP Verification"
          />
          <CustomTypography
            className="login-subtitle"
            style={typography.subtitleS1}
            text="We sent a verification code to the email address you entered"
          />
        </div>
        <div className='login-form otp'>

          <MuiOtpInput value={otp} onChange={handleChange} length={6} />
          {time === 0 ? (
            <ThemeButton
              variant="text"
              style={{ textTransform: 'none' }}
              onClick={handleResendOtp}
              width="100%"
              text="Resend OTP"
              loading={loading}
            />
          ) : (
            <Timer time={time} setTime={setTime} sx={{ mt: 1 }}  />
          )}
          <ThemeButton
            type="submit"
            variant="contained"
            style={{ textTransform: 'none' }}
            onClick={handleSubmit}
            width="100%"
            text="Submit"
            isDisabled={otp?.length !== 6}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
