import React from 'react';
import loading from '../assets/images/common/loading.svg';

const Loader = () => {
  return (
    <>
      <div className="loader-container">
        <img
          src={loading}
          alt="Loading..."
          style={{ width: '50px', height: '50px' }}
        />
      </div>
    </>
  );
};

export default Loader;
