import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PropTypes from "prop-types";
import typography from "../theme/typography";
import BpChecked from "../theme/radio/BpChecked";
import BpIcon from "../theme/radio/BpIcon";
import CustomTypography from "./CustomTypography";

const BpRadio = (props) => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpChecked />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

const CustomRadioButton = ({
  label,
  options,
  defaultValue,
  value,
  handleChange,
  name,
  style,
  className,
  disabled
}) => {
  return (
    <div className="custom-checkbox">
    <FormControl>
      <FormLabel id="demo-customized-radios">
        <CustomTypography style={typography.subtitle2} text={label}/>
      </FormLabel>
      <RadioGroup
        sx={style}
        className={className}
        aria-labelledby="demo-customized-radios"
        name={name}
        value={value}
        onChange={handleChange}
      >
        {options?.map((item) => (
          <div key={item.value} className="flex-container">
            <FormControlLabel
              value={item.value}
              control={<BpRadio />}
              label={item.label}
              checked={
                value === item.value || (!value && defaultValue === item.value)
              }
              disabled={disabled}
            />
          </div>
        ))}
      </RadioGroup>
    </FormControl>
    </div>
  );
};

CustomRadioButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultValue: PropTypes.string,
  showDropDown: PropTypes.bool,
};

export default CustomRadioButton;
