import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slice/users';
import permissionsReducer from './slice/permissions';
import modelReducer from './slice/model';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    permissions: permissionsReducer,
    model: modelReducer,
  },
});
