import React, { useState } from 'react';
import logo from '../../assets/images/logo/re-budget-large.svg';
import user from '../../assets/images/login/user.svg';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../components/TextInput';
import ThemeButton from '../../components/ThemeButton';
import './styles.scss';
import { required, validateField } from '../../utils/Validator';
import { useNotification } from '../../utils/NotificationProvider';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../redux/slice/login';
import { isValidEmail } from '../../utils/helper';
import CustomTypography from '../../components/CustomTypography';
import typography from '../../theme/typography';

const validationRules = {
  email: [required('Email is required'), isValidEmail('Invalid email address')],
};

const ForgotPassword = () => {
  const [values, setValues] = useState({});
  const showNotification = useNotification();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (fieldName) => (event) => {
    setValues({ ...values, [fieldName]: event.target.value });
  };

  const handleSubmit = () => {
    if (!validateField(values, validationRules, setErrors)) return;
    dispatch(
      forgotPassword({
        url: '/users/password/forget',
        method: 'POST',
        data: values,
        navigate,
        success: (res) => {
          if (res && res.message === 'Email sent successfully.') {
            showNotification('success', res.message);
            navigate('/login');
          }
        },
        fail: (err) => {
          showNotification('error', err.message);
        },
      })
    );
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <img src={logo} alt="logo" className='logo-image'></img>
        <CustomTypography
          className="title-text"
          style={typography.headerh3}
          text="Forgot Password"
        />
        <div className='login-form'>
          <TextInput
            label="Email"
            placeholder="Email"
            name="email"
            type="email"
            startAdornment={<img src={user} alt="" />}
            onChange={handleChange('email')}
            helperText={errors.email}
            width="100%"
          />

          <ThemeButton
            type="submit"
            variant="contained"
            style={{ textTransform: 'none' }}
            onClick={handleSubmit}
            isFullWidth={true}
            text="Get new password"
            width="100%"
          />
          <div className="forgot-password-btn">
            <ThemeButton
              type="submit"
              variant="text"
              style={{ textTransform: 'none' }}
              onClick={()=> navigate('/login')}
              isFullWidth={true}
              text={
                <CustomTypography
                  text="Back to Login"
                  style={typography.overline}
                  className="forgot-password-btn"
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
