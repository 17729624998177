import { Button, Drawer, ListItem, ListItemIcon } from "@mui/material";
import logo from "../assets/images/logo/re-budget-logo.png";
import { NavLink, useLocation } from "react-router-dom";
import menuItems from "./menuItems";
import palette from "../theme/palette";
import "./styles.scss";
import CustomTooltip from "../components/CustomTooltip";

const Sidebar = () => {
  const location = useLocation();
  return (
    <>
      <div className="sidebar-wrapper">
        <Drawer variant="permanent">
          <div className="drawer-container">
            <div>
              <img src={logo} alt="log" />
            </div>
            <div>
            {menuItems.map((item) => {
              const isSelected = location.pathname === item.url;
              return (
                <NavLink
                  to={{ pathname: item.url, state: { editMode: false } }}
                  key={item.name}
                >
                  <ListItem disableGutters>
                    <Button
                      style={{
                        backgroundColor: isSelected
                          ? palette.background.primary
                          : palette.background.secondary,
                      }}
                      className="menu-buttons"
                    >
                      <CustomTooltip 
                        title={item.name}
                        placement="right"
                        component={
                         <ListItemIcon style={{ minWidth: 0 }}>
                           <img src={item.icon} alt={item.name} className={isSelected && "image-filter"}/>
                         </ListItemIcon>
                       }
                      />
                    </Button>
                  </ListItem>
                </NavLink>
              );
            })}
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default Sidebar;
