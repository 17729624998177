import { Typography } from '@mui/material';

const CustomTypography = ({
  text,
  variant,
  style,
  component,
  color,
  className,
}) => {
  return (
    <Typography
      variant={variant}
      style={style}
      component={component}
      color={color}
      className={className}
    >
      {text}
    </Typography>
  );
};

export default CustomTypography;
