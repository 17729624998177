import { ThemeProvider } from "@emotion/react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import MainLayout from "./main-layout";
import theme from "./theme";
import { NotificationProvider } from "./utils/NotificationProvider";
import Login from "./views/login";
import SetPassword from "./views/login/setPassword";
import OtpVerification from "./views/login/otpVerification";
import ResetPassword from "./views/login/resetPassword";
import ProtectedRoute from "./routes/ProtectedRoutes";
import ForgotPassword from "./views/login/forgotPassword";

function App() {
  return (
    <NotificationProvider>
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />}/>
          <Route path="/set-password" element={<SetPassword />}/>
          <Route path="/forgot-password" element={<ForgotPassword />}/>
          <Route path="/otp-verification" element={<OtpVerification />}/>
          <Route path="/reset-password" element={<ProtectedRoute element={ResetPassword} />}/>
          <Route
            path="*"
            element={
              <ProtectedRoute element={MainLayout} />
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
    </NotificationProvider>
  );
}

export default App;
