import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ThemeButton from './ThemeButton';
import arrowRight from '../assets/images/common/arrow-right.svg';
import arrowLeft from '../assets/images/common/arrow-left.svg';
import doubleArrow from '../assets/images/common/double-arrow.svg';
// import TextInput from './TextInput';
import CustomTypography from './CustomTypography';
import theme from '../theme';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const TransferList = ({
  leftItems,
  rightItems,
  setLeftItems,
  setRightItems,
  className,
}) => {
  const [checked, setChecked] = useState([]);
  const [selectedList, setSelectedList] = useState();

  const leftChecked = intersection(checked, leftItems);
  const rightChecked = intersection(checked, rightItems);

  const handleToggle = (value, divPosition) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const oppositeList = divPosition === 'left' ? rightItems : leftItems;
    const oppositeChecked = intersection(checked, oppositeList);

    if (oppositeChecked.length > 0) {
      return;
    }

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setSelectedList(divPosition);
  };

  const handleCheckedRight = () => {
    setRightItems(rightItems.concat(leftChecked));
    setLeftItems(not(leftItems, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeftItems(leftItems.concat(rightChecked));
    setRightItems(not(rightItems, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleTransfer = () => {
    if (selectedList === 'left') {
      handleCheckedRight();
    } else {
      handleCheckedLeft();
    }
  };

  const customList = (items, divPositon) => {
    const isRightList =
      rightItems && rightItems.length === 0 && divPositon === 'right';
    return (
      <div
        className={className}
        style={
          divPositon === 'right'
            ? {
                backgroundColor: isRightList && '#F9F9F9',
                display: isRightList && 'flex',
                justifyContent: isRightList && 'center',
                alignItems: isRightList && 'center',
              }
            : null
        }
      >
        {isRightList && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <CustomTypography variant="headerh4" text="No Permissions" color={theme.palette.heading.light} />
            <CustomTypography
              variant="bodyB2"
              text="Select and add from the list on the left"
              color={theme.palette.heading.light}
              style={{maxWidth: '200px', textAlign: 'center'}}
            />
          </div>
        )}
        <List dense component="div" role="list">
          {items?.map((value, index) => {
            const labelId = `transfer-list-item-${value}-label`;
            const isChecked = checked.includes(value);
            const backgroundColor = isChecked
              ? '#864293'
              : index % 2 === 0
                ? '#F7F9FC'
                : 'white';

            return (
              <ListItemButton
                key={value}
                role="listitem"
                onClick={handleToggle(value, divPositon)}
              >
                <div
                  style={{
                    width: '100%',
                    backgroundColor,
                    borderRadius: '4px',
                    padding: '6px 8px',
                  }}
                >
                  <ListItemText id={labelId}>
                    <CustomTypography
                      style={isChecked ? { color: 'white' } : null}
                      text={value.label}
                      variant='subtitle2'
                      className='list-item'
                    />
                  </ListItemText>
                </div>
              </ListItemButton>
            );
          })}
        </List>
      </div>
    );
  };

  const getIcon = () => {
    if (selectedList === 'left') {
      return arrowRight;
    } else if (selectedList === 'right') {
      return arrowLeft;
    } else {
      return doubleArrow;
    }
  };

  const getBackground = () => {
    if (selectedList === 'left') {
      return '#008649';
    } else if (selectedList === 'right') {
      return '#D1293D';
    } else {
      return '#E3E3E3';
    }
  };

  return (
    <div className='permission-list'>
      <div>
        {/* <div className='list-search'>
          <TextInput
            placeholder="All permissions"
            variant="standard"
            width="100%"
          />
          <SearchRoundedIcon />
        </div> */}
        <Grid item style={{height: 'calc(100% - 40px)'}}>{customList(leftItems, 'left')}</Grid>
      </div>
      <div className='transfer-btn'>
        <Grid container direction="column" alignItems="center">
          <ThemeButton
            text={<img src={getIcon()} />}
            onClick={handleTransfer}
            style={{
              backgroundColor: getBackground(),
              width: '48px',
              height: '48px',
            }}
          />
        </Grid>
      </div>
      <div>
        {/* <div className='list-search'>
          <TextInput
            placeholder="Granted permissions"
            variant="standard"
            width='100%'
          />
          <SearchRoundedIcon />
        </div> */}
        <Grid item style={{height: 'calc(100% - 40px)'}}>{customList(rightItems, 'right')}</Grid>
      </div>
    </div>
  );
};

TransferList.propTypes = {
  leftItems: PropTypes.array.isRequired,
  rightItems: PropTypes.array.isRequired,
  setLeftItems: PropTypes.func.isRequired,
  setRightItems: PropTypes.func.isRequired,
  className: PropTypes.any,
};

export default TransferList;
