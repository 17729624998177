import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PropTypes } from 'prop-types';
import { StaticDatePicker } from '@mui/x-date-pickers';
import CustomTypography from './CustomTypography';
import typography from '../theme/typography';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';

const CustomDatePicker = ({
  label,
  onChange,
  datePickerType,
  value,
  title,
  className,
  width,
  name,
  helperText,
  placeholder,
  ref,
  disablePast,
  disabled,
  shouldDisableDate
}) => {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <div className="custom-datepicker" style={{ width: width }}>
        <DemoContainer sx={{ paddingTop: 0 }} components={['DatePicker']}>
          <div>
            {datePickerType === 'static' ? (
              <StaticDatePicker
                placeholder={placeholder} 
                label={label}
                value={value ? dayjs(value) : null}
                inputRef={ref}
                name={name}
                slotProps={{
                  textField: { size: 'small' },
                  label: { style: { color: 'black' } },
                }}
                onChange={handleDateChange}
                disablePast={disablePast}
              /> 
            ) : (
              <div className="datepicker-input">
                <CustomTypography
                  style={{ ...typography.subtitle2, marginBottom: '6px' }}
                  text={title}
                />
                <DatePicker
                  placeholder={placeholder}
                  label={label}
                  value={value ? dayjs(value) : null}
                  name={name}
                  inputRef={ref}
                  slotProps={{
                    textField: { size: 'small', inputProps: { readOnly: true } },
                    label: { style: { color: 'black' } },
                  }}
                  onChange={handleDateChange}
                  className={className}
                  disablePast={disablePast}
                  disabled={disabled}
                  shouldDisableDate={shouldDisableDate}
                />
              </div>
            )}
          </div>
        </DemoContainer>
        {helperText && <span className="error-txt">{helperText}</span>}
      </div>
    </LocalizationProvider>
  );
};

CustomDatePicker.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  datePickerType: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  ref: PropTypes.object,
};
export default CustomDatePicker;
