export default {
    styleOverrides: {
      root: {
        boxShadow: "0px 0px 0px 1px rgba(134, 143, 160, 0.16)",
  
        "& .MuiAccordionSummary-root": {
          minHeight: "20px!important",
          // borderBottom: "1px solid rgba(213, 219, 229, 1)",
        },
        "& .MuiAccordionSummary-content": {
          margin: "10px 0px!important",
        },
      },
    },
  };
  