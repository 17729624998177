import React from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import CustomTypography from './CustomTypography';
import typography from '../theme/typography';
import './styles.scss';

const PhoneNumberInput = ({
  label,
  value,
  onInputChange,
  defaultValue,
  helperText,
}) => {
  return (
    <div className="custom-phone-input">
      <CustomTypography style={typography.subtitle2} text={label} />
      <PhoneInput
        defaultCountry={defaultValue}
        value={value}
        onChange={onInputChange}
      />
      {helperText && <span className="error-txt">{helperText}</span>}
    </div>
  );
};

export default PhoneNumberInput;
