import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextInput from './TextInput';
import './styles.scss'

const CustomAutocomplete = ({ label, options, value, onChange, name, placeholder, isMultiple, width }) => {
  return (
    <div className='custom-autocomplete' style={{width: width}}>
       <Autocomplete
        multiple={isMultiple}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disablePortal
        options={options}
        renderInput={(params) => <TextInput {...params} label={label} />}
      />
    </div>
  );
}

export default CustomAutocomplete;

