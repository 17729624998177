import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import CompanyHierarchy from './CompanyHierarchy';
import TimeStandards from './TimeStandards';
import Roles from './Roles';
import Drivers from './Drivers';
import Calendar from './Calendar';
import Calculations from './Calculations';
import GlobalSettings from './GlobalSettings';
import ThemeButton from '../../../components/ThemeButton';
import VerticalStepper from '../../../components/VerticalStepper';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  budgetTypeOpt,
  currencyOpt,
  weekDays,
  weekModelTabs,
} from './GlobalSettings/constant';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addModel, fetchModel } from '../../../redux/slice/model';
import CompanyCharacteristics from './CompanyCharacteristics';
import { validateField } from '../../../utils/Validator';
import { get } from 'lodash';
import { formatDate } from '../../../utils/helper';
import { useNotification } from '../../../utils/NotificationProvider';
import { ValueTypes } from './GlobalSettings/Group/constant';
import { initialSteps } from '../../../utils/constants';

const AddEditModel = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [modelData, setModelData] = useState({});
  const [errors, setErrors] = useState({});
  const [modelId, setModelId] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const params = useParams();
  const editMode = params.action === 'edit-model';
  const [modelSteps, setModelSteps] = useState([])

  useEffect(() => {
    setModelId(get(params, 'id', ''));
  }, [params]);

  useEffect(() => {
    if (editMode) {
      getModel();
    }
    setModelSteps([...initialSteps]);
  }, []);

  const steps = [
    {
      component: (
        <GlobalSettings
          formData={formData}
          setFormData={setFormData}
          setErrors={setErrors}
          errors={errors}
          modelId={modelId}
          editMode={editMode}
          modelData={modelData}
        />
      ),
      heading: 'Global Settings',
      key: 'globalSettings',
    },
    {
      component: (
        <CompanyHierarchy
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          modelData={modelData}
        />
      ),
      heading: 'Company hierarchy',
      key: 'companyHierarchy',
    },
    {
      component: (
        <CompanyCharacteristics
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          modelData={modelData}
        />
      ),
      heading: 'Company characteristics',
      key: 'companyCharacteristics',
    },
    {
      component: (
        <TimeStandards
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          modelData={modelData}
        />
      ),

      heading: 'Time standards',
      key: 'timeStandard',
    },
    {
      component: (
        <Roles
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          modelData={modelData}
        />
      ),

      heading: 'Roles',
      key: 'roles',
    },
    {
      component: (
        <Drivers
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          modelData={modelData}
        />
      ),

      heading: 'Weekly Drivers',
      key: 'drivers',
    },
    {
      component: (
        <Calendar
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          modelData={modelData}
        />
      ),

      heading: 'Calender data',
      key: 'calenderData',
    },
    {
      component: (
        <Calculations
          formData={formData}
          setFormData={setFormData}
          modelId={modelId}
          editMode={editMode}
          modelData={modelData}
        />
      ),

      heading: 'Calculation',
      key: 'calculation',
    },
  ];

  const getModel = () => {
    dispatch(
      fetchModel({
        url: `/models/${params.id}`,
        method: 'GET',
        navigate,
        success: (res) => {
          const data = get(res, 'data[0].model', {});
          setModelData(data);
        },
        fail: (err) => {
          console.log('err of single model', err);
        },
      })
    );
  };

  const checkForEmptyStrings = (obj, parentKey = '') => {
    let emptyFields = [];
  
    for (const key in obj) {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
  
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        emptyFields = emptyFields.concat(checkForEmptyStrings(obj[key], fullKey));
      } else if (obj[key] === '') {
        // emptyFields.push(fullKey);
        emptyFields.push(key);
      }
    }
    return emptyFields;
  };
  

  /* eslint-disable no-unused-vars */
  const getPayload = (payload, stepName) => {
    let formattedPayload;

    // payload for global settings (step 1)
    if (stepName === 'globalSettings') {
      const {
        fyName,
        currency,
        budgetType,
        budgetStartDate,
        budgetEndDate,
        modelWeeks,
        parameters,
        weekStartDay,
      } = payload;

      const findLabel = (array, value, key) =>
        array.find((item) => item.value === value)?.[key];
      const transformedData = parameters.map((group) => ({
        name: group.groupName,
        parameters: group.parameters.map((param) => ({
          name: param.parameterName,
          note: param.notes || '',
          type: ValueTypes.find((item) => item.value === param.type)?.type,
          values: param.value,
        })),
      }));

      formattedPayload = {
        model: {
          name: fyName,
          budgetType: findLabel(budgetTypeOpt, budgetType, 'label')?.toLowerCase(),
          currency: findLabel(currencyOpt, currency, 'label'),
          numberOfSplit: findLabel(weekModelTabs, modelWeeks, 'title'),
          reportingPeriod: 'monthly',
          weekStartDay: findLabel(weekDays, weekStartDay, 'label')?.toLowerCase(),
          budgetStartDate: budgetStartDate
          ? moment(budgetStartDate).format('YYYY-MM-DD')
          : null,
        budgetEndDate: budgetEndDate
          ? moment(budgetEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
          parameterGroups: transformedData,
        },
      };
    }

    // payload for company hierarchy (step 2)
    if (stepName === 'companyHierarchy') {
      const transformedData = payload.stores.map(
        ({ id, no, name, startDate, endDate, ...rest }) => {
          const structureData = {};
          for (const [key, value] of Object.entries(rest)) {
            structureData[key.charAt(0).toUpperCase() + key.slice(1)] = value;
          }
          return {
            no: no ? Number(no) : '',
            name,
            startDate: startDate ? formatDate(startDate) : '',
            endDate: endDate ? formatDate(endDate) : '',
            structureData,
          };
        }
      );
      const levelData = payload.level.map(
        (lv) => lv.value.charAt(0).toUpperCase() + lv.value.slice(1)
      );
      formattedPayload = {
        hierarchy: {
          level: levelData,
          stores: transformedData,
          structureColumns: payload.structureColumns,
        },
      };
    }

    // payload for company characteristics (step 3)
    if (stepName === 'companyCharacteristics') {
      const transformedData = payload.stores.map(
        ({ no, name, id, ...rest }) => {
          const characteristicData = {};
          for (const [key, value] of Object.entries(rest)) {
            characteristicData[key] = value;
          }
          return {
            _id: id,
            characteristicData,
          };
        }
      );
      formattedPayload = {
        hierarchy_characteristicData: {
          characteristicColumns: payload.characteristicColumns,
          stores: transformedData,
        },
      };
    }
    return formattedPayload;
  };

  const handleAddModelStep = (payloadData, stepName, saveDraft) => {
    const formattedPayload = getPayload(payloadData, stepName);
    const emptyFields = checkForEmptyStrings(formattedPayload);
    if (emptyFields.length > 0) {
      const errorMessage = `Following ${emptyFields.length > 1 ? 'fields are' : 'field is'} required: ${emptyFields.join(', ')}`;
      showNotification('error', errorMessage);
      return;
    }
    dispatch(
      addModel({
        url: '/models/steps',
        method: 'POST',
        navigate,
        data: {
          modelId: modelId || '',
          data: formattedPayload,
        },
        success: (res) => {
          if (stepName === 'globalSettings'){
          setModelId(res.data.model._id);
          }
          if (saveDraft){
            handleClose()
          } else {
          const updatedSteps = modelSteps.map((step, index) => {
            if (index === activeStep) {
              return { ...step, status: 'completed' };
            }
            if (index === activeStep + 1) {
              return { ...step, status: 'inProgress' };
            }
            return step;
          });
          setModelSteps(updatedSteps);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        },
        fail: (err) => {
          console.log('err', err);
          const errorMessage = err.message || 'Something went wrong.';
          showNotification('error', errorMessage);
        },
      })
    );
  };

  const handleNext = (saveDraft) => {
    const stepName = steps[activeStep]?.key;
    const validationRules =
      get(formData, `${[stepName]}.validationRules`) || {};
    const valuesToValidate = formData[stepName] || {};
    if (!validateField(valuesToValidate, validationRules, setErrors)) return;
    handleAddModelStep(valuesToValidate, stepName, saveDraft);
  };

  const handleBack = () => {
    const updatedSteps = modelSteps.map((step, index) => {
      if (index === activeStep) {
        return { ...step, status: 'pending' };
      }
      if (index === activeStep - 1) {
        return { ...step, status: 'inProgress' };
      }
      return step;
    });
    setModelSteps(updatedSteps);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    navigate('/models');
    setActiveStep(0)
  };

  return (
    <>
      <Grid container className="model-container" spacing={3} pr={0}>
        <Grid item className="model-wrapper">
          <VerticalStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={modelSteps}
          />
        </Grid>
        <Grid item className="model-step-container">
          <div className="model-step-wrapper">
            {steps[activeStep].component}
          </div>

          <div>
            <div className="flex-container model-step-footer">
              <ThemeButton
                text="Save Draft"
                onClick={() => handleNext(true)}
                variant="outlined"
              />
              <div className="flex-container" style={{ gap: '10px' }}>
                {activeStep > 0 && (
                  <ThemeButton
                    text="Previous"
                    onClick={handleBack}
                    variant="text"
                  />
                )}

                {activeStep < 6 && (
                  <ThemeButton
                    text="Next step"
                    onClick={() => handleNext(false)}
                    variant="contained"
                  />
                )}
                {activeStep === 6 && (
                  <ThemeButton
                    text="New model"
                    onClick={handleClose}
                    variant="contained"
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

AddEditModel.propTypes = {
  component: PropTypes.element.isRequired,
};
export default AddEditModel;
