import * as React from "react";
import PropTypes from "prop-types";
import TextInput from "./TextInput";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

const CreatableAutocomplete = ({
  options,
  label,
  placeholder,
  name,
  value,
  handleChange,
  className,
  ref,
}) => {

  return (
    <Autocomplete
      value={value}
      name={name}
      className={`custom-autocomplete ${className}`}
      onChange={handleChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.label
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      ref={ref}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextInput {...params} label={label} placeholder={placeholder} ref={ref}/>
      )}
    />
  );
};

CreatableAutocomplete.propTypes = {
  name: PropTypes.string,
  updateOptions: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  mb: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  ref: PropTypes.object,
};

export default CreatableAutocomplete;
