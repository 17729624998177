import React, { useEffect } from 'react';
import './styles.scss';
import typography from '../../theme/typography';

const PasswordStrengthMeter = ({ password, getPasswordStrength }) => {
  const criteria = [
    { label: 'At least 8 characters', regex: /.{8,}/ },
    { label: 'Upper and Lower case letters', regex: /(?=.*[a-z])(?=.*[A-Z])/ },
    { label: 'Letters and numbers', regex: /(?=.*[a-zA-Z])(?=.*\d)/ },
    { label: 'A symbol (!@#?])', regex: /[!@#$%^&*(),.?":{}|<>]/ },
  ];

  const getCriteriaClass = (isMet) => (isMet ? 'met' : 'not-met');

  const calculateStrength = () => {
    let strength = 0;
    criteria.forEach((criterion) => {
      if (criterion.regex.test(password)) {
        strength++;
      }
    });
    return strength;
  };

  const strength = calculateStrength();
  const strengthClass = ['too-weak', 'too-weak', 'could-be-stronger', 'almost-strong', 'now-stronger'][strength]

  useEffect(() => {
    getPasswordStrength(strength)
  }, [strength])

  return (
    <div className="password-strength-meter">
      <div className={`strength-meter ${strengthClass}`}>
        <div className={`strength-bar ${strengthClass}`} style={{ width: `${(strength / criteria.length) * 100}%` }}></div>
      </div>
      <div className="criteria">
        {criteria.map((criterion, index) => {
          const isMet = criterion.regex.test(password);
          return (
            <div key={index} className={`criteria-item ${getCriteriaClass(isMet)}`}>
              <span className={isMet ? 'met' : 'not-met'} style={typography.bodyB2}>
                • {criterion.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PasswordStrengthMeter;
