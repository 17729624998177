import React, { useState } from 'react';
import { IconButton, Paper, Grid } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import cross from "../../../../../assets/images/common/cross.png";
import '../styles.scss';
import CustomTypography from '../../../../../components/CustomTypography';
import typography from '../../../../../theme/typography';
import moment from 'moment';

const ParameterWeeklyCard = ({ title, value, parameter, notes, currency, valueType, onEdit, onDelete }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const getValue = (val) => {
    switch(valueType){
      case 1:
        return (
          moment(val).format('LL')
        );
      case 2: 
      switch(currency){
        case "usd":
          return `$${val}`
        case "eur":
          return `€${val}`
        case "gbp":
          return `£${val}`
        default:
          return val;    
      }
      case 4:
        return `${val}%`  ;
      default:
        return val;  
    }
  }
  return (
    <Grid
      item
      className='parameter-card-container'
      xs={6}
      sm={6}
      key={parameter.id}
      spacing={2}
      pb={2}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Paper className="parameter-card-wrapper">
        <div className="parameter-inner-wrapper">
          <div className='parameter-text'>
          <div className='parameter-content'>
          <CustomTypography text={title} style={typography.semibold}/>
          <CustomTypography text={notes} style={typography.bodyB2}/>
          </div>
          <div>
          <CustomTypography className={valueType === 1 && 'parameter-value'} text={typeof value[0] === 'string' ? getValue(value[0]) : ''} style={valueType !== 1 ? typography.h2 : {}}/>
          {hovered && (
            <div className="card-actions">
              <IconButton
                aria-label="edit"
                sx={{
                  fontSize: '18px',
                  textAlign: 'center',
                }}
                onClick={() => onEdit(parameter)}
              >
                <EditOutlinedIcon fontSize="5px" textAlign="center" />
              </IconButton>
            </div>
          )}
          </div>
          </div>
        </div>
      </Paper>
      {hovered && (
        <div style={{ position: 'absolute', top: -8, right: -10 }}>
        <img src={cross} alt="cross" onClick={() => onDelete(parameter?.id)}/>
        </div>
      )}
      </Grid>
  );
};

export default ParameterWeeklyCard;
