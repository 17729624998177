import React from "react";
import PropTypes from "prop-types";
import { AccordionSummary } from "@mui/material";

const AccordianTitle = (props) => {
    const { children, id, ariaControls, expandIcon } = props;
    return (
        <AccordionSummary
            id={id}
            aria-controls={ariaControls}
            expandIcon={expandIcon}
        >
            {children}
        </AccordionSummary>
    )
}

AccordianTitle.propTypes = {
    children: PropTypes.element,
    ariaControls: PropTypes.string,
    id: PropTypes.string,
    expandIcon: PropTypes.element,
}

export default AccordianTitle;
