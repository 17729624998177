import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import ButtonCell from './ButtonCell';
import TableHeader from './TableHeader';
import { TableFooter } from '@mui/material';
import EnhancedToolbar from './EnhancedToolbar';
import checkCircle from '../../assets/images/common/checkCircle.svg';
import SelectDropdown from '../SelectDropdown';
import TextInput from '../TextInput';
import importicon from '../../assets/images/common/importicon.png';
import moment from 'moment';
import typography from '../../theme/typography';
import './styles.scss';
import CustomTypography from '../CustomTypography';
import CustomAutocomplete from '../CreatableAutocomplete';
import CustomChip from '../CustomChip';
import TableSkeleton from './TableSkeleton';
import CustomAvatar from '../CustomAvatar';
import CustomCheckbox from '../CustomCheckbox';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const DataTable = ({
  rows,
  columns,
  defaultOrderBy,
  defaultOrder,
  selectable,
  pagination,
  headerButtons,
  tableOf,
  updateFilteredRows,
  data,
  onDelete,
  isSearchRequired,
  titleOFthePage,
  showTableHeader,
  cellStyle,
  dropdownItems,
  selectedDropDownValue,
  onChange,
  getColumnBackgroundColor,
  footerRow,
  getAllColumns,
  updateColumnLabel,
  cellPadding,
  highlightDifferences,
  initialFilters,
  searchFilterOpt,
  totalData,
  loading,
  showStatusTabs,
}) => {
  const [order, setOrder] = useState(defaultOrder || 'asc');
  const [orderBy, setOrderBy] = useState(defaultOrderBy || columns[0].id);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(initialFilters?.page - 1);
  const [rowsPerPage, setRowsPerPage] = useState(initialFilters?.limit);
  //   const [uploadFiles, setUploadFiles] = useState(false);
  const [columnLabel, setColumnLabel] = useState('');

  useEffect(() => {
    setSelected([])
  }, [rows])

  useEffect(() => {
    setPage(0)
  }, [totalData])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id)
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleOnUnselect = () => {
    setSelected([]);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    updateFilteredRows({ page: newPage + 1 })
  }

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  const handleChangeRowsPerPage = (event) => {
    const rowsPerPageNo = parseInt(event.target.value, 10)
    setRowsPerPage(rowsPerPageNo);
    setPage(0)
    updateFilteredRows({ limit: rowsPerPageNo, page: 1 })
  };
  //   const handleExport = () => {
  //     setUploadFiles(true);
  //   };
  //   const handleCloseUploadModal = () => {
  //     setUploadFiles(false);
  //   };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () => {
      const sortedRows = stableSort(rows, getComparator(order, orderBy));
      return sortedRows;
    },
    [order, orderBy, page, rowsPerPage, rows]
  );

  const handleInputChange = (value, columnId, currentRow) => {
    const rowIndex = rows.findIndex((row) => row === currentRow);
    const newRows = [...rows];
    newRows[rowIndex][columnId] = value;
    updateFilteredRows(newRows);
  };

  const validateSections = (value, column) => {
    if (
      (column.validInputType === 'text' && !/^[a-zA-Z]*$/.test(value)) ||
      (column.validInputType === 'number' && !/^[0-9]*$/.test(value)) ||
      (column.validInputType === 'percentage' && !/^\d+(\.\d+)?%?$/.test(value))
    ) {
      return true;
    }
  };
  const handleSelectChange = (value, columnId, currentRow, options) => {
    const rowIndex = rows.findIndex((row) => row === currentRow);
    const newRows = [...rows];
    const selectedValue = options.filter((opt) => opt.value === value);
    newRows[rowIndex][columnId] = selectedValue[0];
    updateFilteredRows(newRows);
  };

  const handleAutocompleteChange = (value, currentRow, column, newValue) => {
    const { id, options, toUpdateOptions } = column;
    let selectedValue;
    if (newValue && newValue.inputValue) {
      selectedValue = { label: newValue.inputValue, value: options.length + 1 };
    } else {
      selectedValue = newValue;
    }
    const rowIndex = rows.findIndex((row) => row === currentRow);
    const newRows = [...rows];
    newRows[rowIndex][id] = selectedValue;
    if (newValue && newValue.inputValue) {
      toUpdateOptions([
        ...options,
        { label: newValue.inputValue, value: options.length + 1 },
      ]);
    }
    updateFilteredRows(newRows);
  };

  const getTransformStyle = (column) => {
      if (column.capital){
        return 'uppercase'
      } else if (column.small) {
        return 'lowercase'
      } else {
        return 'capitalize'
      }
    }

  const editCell = (column, row) => {
    switch (column.type) {
      case 'dropdown':
        return (
          <SelectDropdown
            value={
              column?.options?.filter(
                (opt) =>
                  opt?.value === row[column.id]?.value &&
                  opt.label === row[column.id]?.label
              )[0]?.value
            }
            items={column.options || []}
            handleChange={(e) =>
              handleSelectChange(e.target.value, column.id, row, column.options)
            }
          />
        );

      case 'input':
        if (column.validInputType === 'text') {
          return (
            <div>
              <TextInput
                type="text"
                value={row[column.id]}
                width="auto"
                onInputChange={(e) =>
                  handleInputChange(e.target.value, column.id, row, column)
                }
              />
              {validateSections(row[column.id], column) && (
                <span style={{ color: 'red' }}>Please enter a valid value</span>
              )}
            </div>
          );
        } else if (column.validInputType === 'number') {
          return (
            <div>
              <TextInput
                type="text"
                value={row[column.id]}
                width="auto"
                onInputChange={(e) =>
                  handleInputChange(e.target.value, column.id, row, column)
                }
              />
              {validateSections(row[column.id], column) && (
                <span style={{ color: 'red' }}>Please enter a valid value</span>
              )}
            </div>
          );
        } else {
          return (
            <div>
              <TextInput
                type="text"
                value={row[column.id]}
                width="auto"
                onInputChange={(e) =>
                  handleInputChange(e.target.value, column.id, row, column)
                }
              ></TextInput>
            </div>
          );
        }

      case 'autocomplete':
        return (
          <CustomAutocomplete
            options={column.options}
            updateOptions={column.toUpdateOptions}
            value={
              column?.options?.filter(
                (opt) =>
                  opt?.value === row[column.id]?.value &&
                  opt?.label === row[column.id]?.label
              )[0]
            }
            handleChange={(e, newValue) =>
              handleAutocompleteChange(e.target.value, row, column, newValue)
            }
          />
        );

      case 'date':
        return (
          moment(row[column.id]).format('LL')
        );
      case 'buttons':
        return (
          <ButtonCell
            buttons={column.moreOptions.buttons}
            info={row}
            onClick={(index, type) =>
              column.moreOptions.buttonClick(index, row, type)
            }
          />
        );
      case 'chip':
        return <CustomChip initialChips={row[column.id]} toDisplay={column.toDisplay} />;
      case 'logo':
        if (row[column.id]?.length > 1) {
          return <CustomAvatar alt="Remy Sharp" src={row[column.id]} />;
        } else {
          return <CustomAvatar alt="Remy Sharp" name={row[column.id]} />;
        }
      case 'bool':
        return <img src={row[column.id] && checkCircle} alt="" />;
      default:
        return <>{row[column.id]}</>;
    }
  };

  const addNewColumn = () => {
    const newColumnName = columnLabel;
    const newColumn = {
      id: newColumnName?.toLowerCase().replace(' ', ''),
      numeric: false,
      disablePadding: false,
      label: newColumnName,
      searchFilter: true,
      filterType: 'checkbox',
      type: 'input',
      headerType: 'input',
    };
    getAllColumns(newColumn);
    setColumnLabel('');
  };

  const handleColumnInputChange = (value) => {
    setColumnLabel(value);
  };

  return (
    <>
      <>
        <TableHeader
          headerButtons={headerButtons}
          columns={columns}
          rows={rows}
          updateFilteredRows={updateFilteredRows}
          data={data}
          isSearchRequired={isSearchRequired}
          titleOFthePage={titleOFthePage}
          showTableHeader={showTableHeader}
          dropdownItems={dropdownItems}
          selectedDropDownValue={selectedDropDownValue}
          onChange={onChange}
          searchFilterOpt={searchFilterOpt}
          showStatusTabs={showStatusTabs}
          tableOf={tableOf}
        />

        {selected && selected.length > 0 && (
          <EnhancedToolbar
            selectedItems={selected}
            tableOf={tableOf}
            onUnSelect={handleOnUnselect}
            onDelete={onDelete}
            rows={rows}
          />
        )}
        {/* Table Container */}
        <div className="table-wrapper">
          {loading ? <TableSkeleton /> :
            <TableContainer sx={{ maxWidth: '94vw', scrollbarWidth: 'thin' }}>
              {/* Table */}
              <Table
                sx={{ minWidth: 750, overflow: 'auto' }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                {/* Table Head */}
                <TableHead>
                  <TableRow>
                    {selectable && (
                      <TableCell padding="checkbox">
                        <CustomCheckbox
                          color="primary"
                          indeterminate={
                            selected.length > 0 && selected.length < rows.length
                          }
                          checked={
                            rows.length > 0 && selected.length === rows.length
                          }
                          handleChange={handleSelectAllClick}
                          inputProps={{
                            'aria-label': 'select all items',
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.numeric ? 'right' : 'left'}
                          padding={column.disablePadding ? 'none' : 'normal'}
                          sortDirection={orderBy === column.id ? order : false}
                          width={column.width || ''}
                        >
                          {column.sortable ? (
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              onClick={() => handleRequestSort(null, column.id)}
                            >
                              <CustomTypography
                                style={typography.overline}
                                text={column.label}
                              />
                              {orderBy === column.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : column.headerType === 'input' ? (
                            column.id === 'newcharacteristics' ? (
                              <TextInput
                                className="bg-white"
                                type="text"
                                value={columnLabel || ''}
                                width={column.width}
                                placeholder={column.placeholder}
                                onInputChange={(e) =>
                                  handleColumnInputChange(e.target.value)
                                }
                                endAdornment={
                                  <img
                                    src={importicon}
                                    className="icon-size"
                                  //   onClick={handleExport}
                                  />
                                }
                                onBlur={addNewColumn}
                              />
                            ) : (
                              <TextInput
                                type="text"
                                value={column.label || ''}
                                className="bg-white"
                                endAdornment={
                                  <img
                                    src={importicon}
                                    className="icon-size"
                                  //   onClick={handleExport}
                                  />
                                }
                                onInputChange={(e) => {
                                  const newColumns = columns.map((col) =>
                                    col.id === column.id
                                      ? { ...col, label: e.target.value }
                                      : col
                                  );
                                  updateColumnLabel(newColumns);
                                }}
                              />
                            )
                          ) : (
                            <CustomTypography
                              style={typography.overline}
                              text={column.label}
                            />
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                <TableBody>
                  {visibleRows && visibleRows.length > 0 ? (
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row[columns[0].id]}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          {selectable && (
                            <TableCell padding="checkbox">
                              <CustomCheckbox
                                color="primary"
                                checked={isItemSelected}
                                onClick={(event) =>
                                  handleClick(event, row.id)
                                }
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                          )}
                          {columns.map((column, index) => {
                            const backgroundColor =
                              highlightDifferences &&
                                row.differentCell &&
                                row.differentCell.some(
                                  (cell) => cell.column === column.id
                                )
                                ? '#EEE7DE'
                                : cellStyle
                                  ? getColumnBackgroundColor(index)
                                  : '';
                            const border =
                              highlightDifferences &&
                                row.differentCell &&
                                row.differentCell.some(
                                  (cell) => cell.column === column.id
                                )
                                ? '2px solid red'
                                : '';

                            const cellStyleObj = {
                              backgroundColor,
                              padding: cellStyle ? '8px' : '',
                              borderBottom: cellStyle ? '1px solid white' : '',
                              border,
                            };

                            return (
                              <TableCell
                                key={column.id}
                                sx={{ width: column?.width, textTransform: getTransformStyle(column) }}
                                align={column.numeric ? 'right' : 'left'}
                                className={`datepicker-div ${cellPadding}`}
                                style={cellStyleObj}
                              >
                                {editCell(column, row)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={columns.length + (selectable ? 1 : 0)} style={{ padding: '16px' }}>
                        <CustomTypography variant="body1" text="No results found" />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {footerRow &&
                      columns.map((column) => (
                        <TableCell key={column.id}>
                          {footerRow[column.id]}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>}
        </div>
        {/* Table Pagination */}
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </>
    </>
  );
};

DataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultOrderBy: PropTypes.string,
  headerButtons: PropTypes.array,
  defaultOrder: PropTypes.oneOf(['asc', 'desc']),
  selectable: PropTypes.bool,
  pagination: PropTypes.bool,
  tableOf: PropTypes.array,
  data: PropTypes.array,
  updateFilteredRows: PropTypes.func,
  onDelete: PropTypes.func,
  isSearchRequired: PropTypes.bool,
  titleOFthePage: PropTypes.string,
  showTableHeader: PropTypes.bool,
  cellStyle: PropTypes.bool,
  dropdownItems: PropTypes.array,
  selectedDropDownValue: PropTypes.string,
  onChange: PropTypes.func,
  getAllColumns: PropTypes.func,
  getColumnBackgroundColor: PropTypes.func,
  updateColumnLabel: PropTypes.func,
  footerRow: PropTypes.object,
  cellPadding: PropTypes.string,
  highlightDifferences: PropTypes.bool,
  NoDataFoundTitle: PropTypes.string,
  NoDataFoundText: PropTypes.string
};
DataTable.defaultProps = {
  isSearchRequired: true,
  showTableHeader: true,
  showStatusTabs: true
};
export default DataTable;
