import React from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";

const CustomMenu = (props) => {
  const { children, isMenuOpen, onClose, anchorOrigin, transformOrigin } =
    props;
  return (
    <Menu
      elevation={0}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      id="customized-menu"
      MenuListProps={{
        "aria-labelledby": "customized-button",
      }}
      anchorEl={isMenuOpen}
      open={isMenuOpen}
      onClose={onClose}
    >
      {children}
    </Menu>
  );
};

CustomMenu.propTypes = {
  children: PropTypes.element,
  onClose: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  anchorOrigin: PropTypes.bool,
  transformOrigin: PropTypes.bool,
};

export default CustomMenu;
