import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton
} from '@mui/material';
import './styles.scss'

const TableSkeleton = ({ rows = 6, columns = 5 }) => {
  return (
    <div className='custom-table-skeleton'>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
          <TableCell>
              <Skeleton variant="rounded" width={24} height={24} />
            </TableCell>
            {Array.from(new Array(columns - 1)).map((_, index) => (
              <TableCell key={index}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(new Array(rows)).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell width="10%">
                <Skeleton variant="rounded" width={24} height={24} />
              </TableCell>
              {Array.from(new Array(columns - 1)).map((_, colIndex) => (
                <TableCell key={colIndex}>
                  <Skeleton variant="rounded" width="100%" height={30} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default TableSkeleton;
