import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LoadingDone from '../assets/images/common/loadingDone.svg';
import typography from '../theme/typography';
import CustomTypography from './CustomTypography';
import ThemeButton from './ThemeButton';
import { Stack } from '@mui/material';

const ProgressCircle = (props) => {
  const { value, size = 100, showText, onCancelUpload } = props;
  return (
    <div className='progress-circle-wrapper'>
      <div className="custom-progress-circle">
        <CircularProgress
          variant="determinate"
          size={size}
          value={100}
          className="default-progress-circle"
        />
        <CircularProgress
          variant="determinate"
          size={size}
          value={value}
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {value >= 100 ? (
            <img src={LoadingDone} />
          ) : (
            <CustomTypography
              style={typography.headerh4}
              text={`${Math.round(value)}%`}
            />
          )}
        </Box>
      </div>
      <div>
        {showText && value < 100 && (
            <Stack spacing={1}>
            <CustomTypography
              text='Uploading file...'
              style={typography.caption}
              color="#868FA0"
            /> 
            <ThemeButton text="Cancel" variant="outlined" width="100%" onClick={onCancelUpload}/>
            </Stack>
        )}
        {showText && value >= 100 && (
            <CustomTypography
              text='Done'
              style={typography.caption}
              color="#3C4555"
            /> 
        )}
      </div>
    </div>
  );
};

ProgressCircle.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.number,
};

export default ProgressCircle;
