import { styled } from "@mui/material/styles";
import BpIcon from "./BpIcon";

const BpChecked = styled(BpIcon)({
  backgroundColor: "#675967",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#675967",
    outline: "#5B1568"
  },
  "input:disabled ~ &": {
    boxShadow: "0px 0px 0px 1px #5B156833",
    background: "#C5BFC5",
  },
});

export default BpChecked;
