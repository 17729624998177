import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import './styles.scss'

const ThemeButton = (props) => {
  const {
    text,
    variant,
    startIcon,
    onClick,
    ariaControls,
    ariaHaspopup,
    ariaExpanded,
    isDisabled,
    endIcon,
    style,
    className,
    loading,
    color,
    type,
    width
  } = props;
  return (
    <div className="theme-button">
      <Button
        variant={variant}
        startIcon={startIcon}
        onClick={onClick}
        className={className}
        sx={{...style, textTransform: 'capitalize', width: `${width} !important`}}
        loading={loading}
        aria-controls={ariaControls}
        aria-haspopup={ariaHaspopup}
        aria-expanded={ariaExpanded}
        disabled={isDisabled}
        endIcon={endIcon}
        color={color}
        type={type}
      >
        {loading ? "Loading..." : text}
      </Button>
      </div>
  );
};

ThemeButton.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
  isFullWidth: PropTypes.bool,
  isEdit: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  style: PropTypes.object,
  ariaControls: PropTypes.string,
  ariaHaspopup: PropTypes.string,
  ariaExpanded: PropTypes.string,
  isDisabled: PropTypes.bool,
  endIcon: PropTypes.any,
  color: PropTypes.any,
  type: PropTypes.string,
  width: PropTypes.string
};

ThemeButton.defaultProps = {
  loading: false,
  style: {},
  isFullWidth: false,
  isDisabled: false,
};

export default ThemeButton;
