import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from '../../utils/asyncThunk';

export const loginUser = createApiThunk('loginUser');
export const setPassword = createApiThunk('setPassword');
export const resetPassword = createApiThunk('resetPassword');
export const verifyOtp = createApiThunk('verifyOtp');
export const resendOtp = createApiThunk('resendOtp');
export const forgotPassword = createApiThunk('forgotPassword');

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    // Login User
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.isError = true;
    });

    //  Set password
    builder.addCase(setPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(setPassword.rejected, (state) => {
      state.isError = true;
    });

    //  Reset password
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.isError = true;
    });

    //  OTP Verification
    builder.addCase(verifyOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(verifyOtp.rejected, (state) => {
      state.isError = true;
    });

    // Resend OTP 
    builder.addCase(resendOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resendOtp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(resendOtp.rejected, (state) => {
      state.isError = true;
    });

    // Forgot Password
    builder.addCase(forgotPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(forgotPassword.rejected, (state) => {
      state.isError = true;
    });
  },
});

export default loginSlice.reducer;
