import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import { countries } from '../../../../utils/constants';
import refresh from '../../../../assets/images/common/refresh.svg';
import UploadFiles from '../../../../components/UploadFiles';
import ActionModal from '../../../../components/ActionModal';
import * as XLSX from 'xlsx';
import { get } from 'lodash';
import { parse } from 'papaparse';
import { useDispatch } from 'react-redux';
import { getHierarchy } from '../../../../redux/slice/model';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../../utils/NotificationProvider';

const CompanyCharacteristics = ({ formData, setFormData, modelId }) => {
  const [filteredRows, setFilteredRows] = useState([]);
  const [isUploadModal, setUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const showNotification = useNotification();

  const handleCloseModal = () => {
    setUploadModal(false);
    setUploadedFile(null);
  };

  const headerButtons = [
    {
      text: 'Import Charcteristics',
      variant: 'outlined',
      color: 'success',
      width: '114px !important',
      startIcon: <img src={refresh} alt="refresh" />,
      onClick: () => setUploadModal(true),
    },
  ];

  useEffect(() => {
    fetchHierarchy()
  }, []);

  const fetchHierarchy = () => {
    dispatch(getHierarchy({
      url: `/hierarchies/get/${modelId}`,
      method: 'GET',
      navigate,   
      success: (res) => {
        if (get(res, 'data.stores') && get(res, 'data.stores').length > 0){
          const stores = res.data.stores;
          const storeCharacteristics = stores.map(({no, name,_id, characteristicData}) => ({
            id: _id,
            no,
            name,
            newCharacteristics: 'e.g.1',
            ...characteristicData
          }));
          setFilteredRows(storeCharacteristics)
        } else {
          setFilteredRows([]);
        }
        if (get(res, 'data.characteristicColumns') && get(res, 'data.characteristicColumns').length > 0){
          const updatedColumns = get(res, 'data.characteristicColumns')
          setUpdatedColumns(updatedColumns)
        } else {
          setUpdatedColumns(columns)
        }
      },
      fail: (err) => {
        console.log('err hierarchy', err)
      }
    }))
  }

  useEffect(() => {
    setFormData({
      ...formData,
      companyCharacteristics: {
        stores: filteredRows,
        characteristicColumns: updatedColumns
      },
    });
  }, [filteredRows, updatedColumns]);

  const updateFilteredRows = (rows) => {
    const data = rows.data ? rows.data : filteredRows;
    setFilteredRows(data);
  };

  const getUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const getAllColumns = (newColumn) => {
    const lastColumnIndex = updatedColumns.length - 1;
    const updatedColumnsCopy = [
      ...updatedColumns.slice(0, lastColumnIndex),
      newColumn,
      ...updatedColumns.slice(lastColumnIndex),
    ];
    setUpdatedColumns(updatedColumnsCopy);
  };

  const updateColumnLabel = (allColumns) => {
    setUpdatedColumns(allColumns);
  };

  const handleImportData = () => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target.result;
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();

      let parsedData;
      let headers;

      if (fileType === 'csv') {
        const csvData = parse(fileContent, { header: true });
        parsedData = csvData.data;
        headers = csvData.meta.fields;
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        headers = worksheet[0];
        parsedData = worksheet.slice(1).map((row) =>
          row.reduce((acc, value, index) => {
            acc[headers[index]] = value;
            return acc;
          }, {})
        );
      }
      if (headers[0] !== 'no' || headers[1] !== 'name'){
        showNotification('error', "Please ensure the imported file matches the template format.")
        return;
      }
      const existingColumnIds = updatedColumns.map((column) => column.id);
      const newHeaders = headers.filter(
        (header) => !existingColumnIds.includes(header)
      );

      if (newHeaders.length > 0) {
        const newColumnsObjects = newHeaders.map((header) => ({
          id: header,
          numeric: false,
          disablePadding: false,
          label: header.toUpperCase().replace(/_/g, ' '),
          sortable: true,
          searchFilter: true,
          filterType: 'checkbox',
          width: '10%',
          small: true,
          type: 'input',
        }));
        const lastColumnIndex = updatedColumns.length - 1;
        const updatedColumnsCopy = [
          ...updatedColumns.slice(0, lastColumnIndex),
          ...newColumnsObjects,
          ...updatedColumns.slice(lastColumnIndex),
        ];
        setUpdatedColumns(updatedColumnsCopy);
      }

      const updatedRows = filteredRows.map((filteredRow) => {
        const matchedRow = parsedData.find(
          (parsedRow) =>
            Number(parsedRow.no) === filteredRow.no &&
            parsedRow.name === filteredRow.name
        );
        return matchedRow ? { ...filteredRow, ...matchedRow } : filteredRow;
      });
      setFilteredRows(updatedRows);
    };

    const fileType = uploadedFile.name.split('.').pop().toLowerCase();
    if (fileType === 'csv') {
      reader.readAsText(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }

    handleCloseModal();
  };

  const columns = [
    {
      id: 'no',
      numeric: false,
      disablePadding: false,
      label: 'NO.',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      width: '10%',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      sortable: true,
      filterType: 'checkbox',
      width: '10%',
      options: countries,
    },
    {
      id: 'newCharacteristics',
      numeric: false,
      disablePadding: false,
      placeholder: 'Type and press enter to add New characteristics',
      type: 'input',
      searchFilter: true,
      filterType: 'checkbox',
      headerType: 'input',
      width: '10%',
    },
  ];

  const actionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    {
      title: 'Import Data',
      variant: 'contained',
      onClick: handleImportData,
      isDisabled: uploadedFile ? false : true,
    },
  ];

  return (
    <div>
      <div className="step-header">
        <CustomTypography variant="headerh3" text="Company Charcteristics" />
      </div>
      <EditableTable
        columns={updatedColumns}
        rows={filteredRows}
        data={filteredRows}
        tableOf={['heirarchy']}
        showStatusTabs={false}
        updateFilteredRows={updateFilteredRows}
        defaultOrderBy="calories"
        defaultOrder="asc"
        headerButtons={headerButtons}
        getAllColumns={getAllColumns}
        updateColumnLabel={updateColumnLabel}
      />
      <ActionModal
        open={isUploadModal}
        close={handleCloseModal}
        component={
        <UploadFiles 
          getUploadedFile={getUploadedFile} 
          templateSrc="/assets/company-characteristics-template.csv"
          additionalNote="Note: Ensure that the 'no' and 'name' fields match those defined in the company hierarchy for accurate data import."
        />}
        actionButtons={actionButtons}
        title="Upload Files"
        width="32%"
      />
    </div>
  );
};

export default CompanyCharacteristics;
