import React from "react";
import CustomTypography from "../../../../components/CustomTypography";

const TimeStandards = () => {
   return (
      <div className="form-title">
        <CustomTypography
          variant="headerh3"
          text="Time Standards"
        />
      </div>
  )
}

export default TimeStandards;