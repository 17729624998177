import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

const MenuItemList = (props) => {
    const { children, key, sx, className } = props;
    return (
        <MenuItem
            key={key}
            sx={sx}
            className={className}
        >
            {children}
        </MenuItem>
    )
}

MenuItemList.propTypes = {
    children: PropTypes.element,
    sx: PropTypes.obj,
    key: PropTypes.string,
    className: PropTypes.string,
}

export default MenuItemList;
